import { connect } from 'react-redux';
import { getUserSelector } from 'selectors/getUserSelector';
import { addUserThunkAction } from 'thunks/users/addUserThunkAction';
import { deleteUserThunkAction } from 'thunks/users/deleteUserThunkAction';
import { getUsersThunkAction } from 'thunks/users/getUsersThunkAction';
import { modifyUserThunkAction } from 'thunks/users/modifyUserThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { IDispatchProps, IStateProps } from './types';
import { UserPage } from './UserPage';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    loadUsers: () => dispatch(getUsersThunkAction()),
    modify: (data) => dispatch(modifyUserThunkAction(data)),
    save: (user) => dispatch(addUserThunkAction(user)),
    del: (id) => dispatch(deleteUserThunkAction(id)),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    user: getUserSelector(state),
    users: state.usersReducer.users,
    areas: state.areasReducer.areas,
});

export const UserPageConnected = connect(mapStateToProps, mapDispatchToProps)(UserPage);
