import { createReducer } from '@reduxjs/toolkit';
import { resetMapReducerAction } from 'actions/map/resetMapReducerAction';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { setFilterAction } from 'actions/map/setFilterAction';
import { setMapAction } from 'actions/map/setMapActions';
import { setMapModeAction } from 'actions/map/setMapModeAction';
import { setObjectManagerAction } from 'actions/map/setObjectManagerAction';
import { IPlacemarkData } from 'types/IPlacemarkData';
import { TMapMode } from 'types/TMapMode';

interface IMapReducerInitialState {
    map: any | null;
    currentPlacemarkData: IPlacemarkData | null;
    mapMode: TMapMode;
    objectManager: any | null;
    filter: { [type: string]: { [id: string]: string | string[] } };
}

const mapReducerInitialState: IMapReducerInitialState = {
    map: null,
    currentPlacemarkData: null,
    mapMode: 'point',
    objectManager: null,
    filter: {},
};

export const mapReducer = createReducer(mapReducerInitialState, (builder) =>
    builder
        .addCase(resetMapReducerAction, () => mapReducerInitialState)
        .addCase(setMapAction, (state, { payload: map }) => {
            state.map = map;
        })
        .addCase(setObjectManagerAction, (state, { payload: manager }) => {
            state.objectManager = manager;
        })
        .addCase(setCurrentPlacemarkAction, (state, { payload: placemark }) => {
            state.currentPlacemarkData = placemark;
        })
        .addCase(setMapModeAction, (state, { payload: mode }) => {
            state.mapMode = mode;
        })
        .addCase(setFilterAction, (state, { payload: newFilter }) => {
            state.filter = newFilter;
            if (Object.keys(newFilter).length === 0) {
                state.objectManager.setFilter();
            } else {
                state.objectManager.setFilter((object: any) => {
                    for (const type in newFilter) {
                        if (type === 'Supply station') {
                            const fieldFilter = newFilter[type];
                            for (const field in fieldFilter) {
                                const value = fieldFilter[field];
                                if (typeof value === 'string') {
                                    return (
                                        object.properties[field] === value ||
                                        object.properties?.id === value
                                    );
                                }
                            }
                        } else {
                            const fieldFilter = newFilter[type];
                            for (const field in fieldFilter) {
                                const value = fieldFilter[field];
                                if (typeof value === 'string') {
                                    return object.properties[field] === value;
                                } else {
                                    const valuesArray = value as string[];
                                    return valuesArray.includes(object.properties[field]);
                                }
                            }
                        }
                    }
                });
            }
        }),
);
