import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyUserAction } from 'actions/users/modifyUserAction';
import { getNetworkService } from 'api/getNetworkService';
import { modifyUserApiCall } from 'api/users/modifyUserApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { IUser } from 'types/IUser';
import { TAppState } from 'types/TAppState';

export const modifyUserThunkAction = createAsyncThunk<
    undefined | string,
    { id: string; fields: Partial<Omit<IUser, 'id'>> }
>('modifyUser', async ({ fields, id }, { getState, dispatch }) => {
    const {
        usersReducer: { users },
    } = getState() as TAppState;
    const dataToSend: { [id: string]: any } = {};
    const currentUser = users.find((user) => user.id === id) as IUser;
    for (const field in fields) {
        const key = field as keyof Partial<Omit<IUser, 'id'>>;
        if (key === 'password' && fields[key] !== '') {
            dataToSend[key] = fields[key];
        } else if (currentUser[key] !== fields[key] && key !== 'password') {
            dataToSend[key] = fields[key];
        }
    }
    const response = await modifyUserApiCall(getNetworkService(), { id, fields: dataToSend });
    if (!response.success) {
        dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        return response.statusText;
    }
    dispatch(showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }));
    dispatch(modifyUserAction({ id, fields: dataToSend }));
});
