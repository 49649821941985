import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetLineReducerAction } from 'actions/line/resetLineReducerAction';
import { TAppState } from 'types/TAppState';

export const resetLineStoreThunkAction = createAsyncThunk(
    'resetLineStore',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map },
            lineReducer: { linePoints, points, line },
        } = getState() as TAppState;
        linePoints.forEach((placemark) => {
            if (placemark) map.geoObjects.remove(placemark);
        });
        points.forEach((placemark) => {
            if (placemark) map.geoObjects.remove(placemark);
        });
        map.geoObjects.remove(line);
        dispatch(resetLineReducerAction());
    },
);
