import Axios, { AxiosBasicCredentials, AxiosInstance, AxiosRequestConfig } from 'axios';
import { INetworkService } from 'types/api/INetworkService';
import { log } from 'utils/log';

interface IAxiosClient {
    networkService: INetworkService;
    axiosInstance: AxiosInstance;
    auth?: AxiosBasicCredentials;
    getAxiosRequestConfig: (auth?: AxiosBasicCredentials) => AxiosRequestConfig;
}

let networkServiceInstance: IAxiosClient;

const createAxiosInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: process.env.NODE_ENV === 'test' ? 'http://localhost' : '',
    };

    if (process.env.NODE_ENV === 'test') {
        config.adapter = require('axios/lib/adapters/http');
    }
    return Axios.create(config);
};

const errorHandler = (e: any) => {
    const { response } = e;
    log.error(response.status, response.statusText);
    return { ...e.response, success: false };
};

export const getNetworkService = (): INetworkService => {
    if (!networkServiceInstance) {
        networkServiceInstance = {
            auth: undefined,
            axiosInstance: createAxiosInstance(),
            getAxiosRequestConfig: (authToTry?: AxiosBasicCredentials): AxiosRequestConfig =>
                ((auth) =>
                    auth ? { auth, headers: { 'X-Requested-With': 'XMLHttpRequest' } } : {})(
                    authToTry || networkServiceInstance.auth,
                ),
            networkService: {
                delete: ({ url, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .delete(url, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(),
                        ...config,
                    }),
                get: ({ url, onProgress, auth, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .get(url, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(auth),
                        onDownloadProgress: onProgress,
                        ...config,
                    }),
                post: ({ url, data, onProgress, auth, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .post(url, data, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(auth),
                        onUploadProgress: onProgress,
                        ...config,
                    }),
                put: ({ url, data, ...config }) =>
                    ((config) =>
                        networkServiceInstance.axiosInstance
                            .put(url, data, config)
                            .then((response) => ({ ...response, success: true }))
                            .catch(errorHandler))({
                        ...networkServiceInstance.getAxiosRequestConfig(),
                        ...config,
                    }),
            },
        };
    }
    return networkServiceInstance.networkService;
};
