import { INetworkService } from 'types/api/INetworkService';
import { ILampModel } from 'types/ILampModel';

interface IPayload {
    id: string;
    fields: Partial<ILampModel>;
}

export const updateLampModelApiCall = (networkService: INetworkService, { fields, id }: IPayload) =>
    networkService.put({ url: `/api/lampModels/${id}`, data: fields });
