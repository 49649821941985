import { TMapMode } from 'types/TMapMode';
import { TModeString } from 'types/TModeString';

export const convertModeToString = (mode: TMapMode): TModeString => {
    if (mode === 'line') {
        return 'Установка по линии';
    } else if (mode === 'point') {
        return 'Работа с точками';
    } else if (mode === 'select') {
        return 'Подсчет';
    } else if (mode === 'areas') {
        return 'Области';
    }
    return 'Работа с точками';
};
