import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyLampModelAction } from 'actions/lampModels/modifyLampModelAction';
import { getNetworkService } from 'api/getNetworkService';
import { updateLampModelApiCall } from 'api/lampModels/updateLampModelApiCall';
import { ILampModel } from 'types/ILampModel';

interface IPayload {
    id: string;
    fields: Partial<ILampModel>;
}

export const modifyLampModelThunkAction = createAsyncThunk<void | string, IPayload>(
    'modifyLampModel',
    async ({ fields, id }, { dispatch }) => {
        delete fields['id'];
        const response = await updateLampModelApiCall(getNetworkService(), {
            id,
            fields,
        });
        if (response.success) {
            dispatch(modifyLampModelAction({ id, fields: fields }));
        } else {
            return 'error';
        }
    },
);
