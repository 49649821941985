import { connect } from 'react-redux';
import { getCurrentAreaSelector } from 'selectors/getCurrentAreaSelector';
import { addAreaThunkAction } from 'thunks/areas/addAreaThunkAction';
import { deleteAreaThunkAction } from 'thunks/areas/deleteAreaThunkAction';
import { modifyAreaThunkAction } from 'thunks/areas/modifyAreaThunkAction';
import { resetCurrentAreaThunkAction } from 'thunks/areas/resetCurrentAreaThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { AreaMenu } from './AreaMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentPlacemark: state.areasReducer.currentArea,
    currentArea: getCurrentAreaSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    addArea: (data) => dispatch(addAreaThunkAction(data)),
    deleteArea: () => dispatch(deleteAreaThunkAction()),
    resetCurrentArea: () => dispatch(resetCurrentAreaThunkAction()),
    modifyArea: (fields) => dispatch(modifyAreaThunkAction(fields)),
});

export const AreaMenuConnected = connect(mapStateToProps, mapDispatchToProps)(AreaMenu);
