import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { IFeature } from 'types/feature/IFeature';
import { ILamp } from 'types/ILamp';
import { ILampModel } from 'types/ILampModel';
import { ISupplyStation } from 'types/ISupplyStation';
import { getLampColor } from './getLampColor';

export const getFeature = (
    lamp: ILamp | null,
    supplyStation: ISupplyStation | null,
    lampModels: ILampModel[],
): IFeature | undefined => {
    if (lamp) {
        const { coordinates, model, placement, power, supply_station, id } = lamp;
        const color = getLampColor(lamp, lampModels);
        return {
            id,
            type: 'Feature',
            geometry: {
                coordinates,
                type: 'Point',
            },
            properties: {
                type: 'Lamp',
                supply_station,
                placement,
                model,
                power: power.toString(),
                id,
            },
            options: {
                preset: EPlacemarkTypes.LAMP,
                iconColor: color,
            },
        };
    } else if (supplyStation) {
        const { cabinet, coordinates, id, name, organization } = supplyStation;
        return {
            id,
            type: 'Feature',
            geometry: {
                coordinates,
                type: 'Point',
            },
            options: {
                preset: EPlacemarkTypes.SUPPLY_STATION,
                iconColor: EPlacemarkColors.SUPPLY_STATION,
            },
            properties: {
                type: 'Supply station',
                organization,
                cabinet,
                iconContent: name,
                id,
            },
        };
    }
};
