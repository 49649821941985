import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetSelectReducerAction } from 'actions/select/resetSelectReducerAction';
import { TAppState } from 'types/TAppState';
import { mouseDownEvent, mouseUpEvent } from './setSelectModeThunkAction';

export const resetSelectStoreThunkAction = createAsyncThunk(
    'resetSelectStore',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map },
            selectReducer: { area },
        } = getState() as TAppState;
        if (area) {
            map.geoObjects.remove(area);
        }
        if (mouseDownEvent) {
            map.events.remove('mousedown', mouseDownEvent);
        }
        if (mouseUpEvent) {
            map.events.remove('mouseup', mouseUpEvent);
        }
        dispatch(resetSelectReducerAction());
    },
);
