import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ExportPage.module.scss';
import { IStateProps } from './types';

export const ExportPage: React.FC<IStateProps> = ({ areas }) => {
    const wrapperStyle = `container-xl ${styles.wrapper}`;
    const [area, setArea] = useState<string>('all');
    // const onClick = (url: string) => {
    //     const a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.href = url;
    //     a.download = 'file.csv';
    //     a.click();
    //     document.body.removeChild(a);
    // };

    return (
        <div className={wrapperStyle}>
            <Link to='/menu'>Назад</Link>
            <div className={`row ${styles.areas}`}>
                <label>Выберите зону для выгрузки</label>
                <select className='form-select' onChange={(e) => setArea(e.target.value)}>
                    <option value='all' key={'all'}>
                        Все
                    </option>
                    {areas.map((item) => (
                        <option value={item.id} key={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>

            <ul className='list-group'>
                <a
                    href={`/api/export/all?area=${area}`}
                    className='list-group-item list-group-item-action'>
                    Все данные
                </a>
                <a
                    href={`/api/export/supply_stations?area=${area}`}
                    className='list-group-item list-group-item-action'>
                    По ТП
                </a>
            </ul>
        </div>
    );
};
