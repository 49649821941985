import { connect } from 'react-redux';
import { setFilterThunkAction } from 'thunks/map/setFilterThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LampModelsFilter } from './LampModelsFilter';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    lampModels: state.lampModelsReducer.lampModels,
    filter: state.mapReducer.filter,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setFilter: (filter) => dispatch(setFilterThunkAction(filter)),
});

export const LampModelsFilterConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LampModelsFilter);
