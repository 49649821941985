export const getPointsByCount = (
    line: [[number, number], [number, number]],
    count: number,
): number[][] => {
    const points = [];
    let deltaCount = count;
    for (let i = 1; i <= count; i++) {
        const delta = i / deltaCount;
        const x = (line[0][0] + delta * line[1][0]) / (1 + delta);
        const y = (line[0][1] + delta * line[1][1]) / (1 + delta);
        points.push([x, y]);
        deltaCount--;
    }
    return points;
};
