import { connect } from 'react-redux';
import { destroyMapThunkAction } from 'thunks/map/destroyMapThunkAction';
import { initMapThunkAction } from 'thunks/map/initMapThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { Map } from './Map';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentPlacemarkData: state.mapReducer.currentPlacemarkData,
    mapMode: state.mapReducer.mapMode,
    lampModels: state.lampModelsReducer.lampModels,
    map: state.mapReducer.map,
    currentArea: state.areasReducer.currentArea,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    initMap: () => dispatch(initMapThunkAction()),
    destroyMap: () => dispatch(destroyMapThunkAction()),
});

export const MapConnected = connect(mapStateToProps, mapDispatchToProps)(Map);
