import { IPlacemark } from 'types/IPlacemark';
import { setPlacemarkProperty } from 'utils/placemarkProperties/setPlacemarkProperty';

export const setPlacemarkAddress = async (placemark: IPlacemark): Promise<string> => {
    const coordinates = placemark.geometry.getCoordinates();
    const geocodeResult = await ymaps.geocode(coordinates);
    const object = geocodeResult.geoObjects.get(0);
    const address = object.getAddressLine();
    setPlacemarkProperty(placemark, { address });
    return address || '';
};
