import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoginRequestStatusAction } from 'actions/account/setLoginRequestStatusAction';
import { setUserAction } from 'actions/account/setUserAction';
import { getCurrentUserApiCall } from 'api/account/getCurrentUserApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { loadLampModelsThunkAction } from 'thunks/lampModels/loadLampModelsThunkAction';
import { getUserSettingsThunkAction } from 'thunks/userSettings/getUserSettingsThunkAction';
import { IUser } from 'types/IUser';
import { TAppState } from 'types/TAppState';

export const getCurrentUserThunkAction = createAsyncThunk(
    'getCurrentUser',
    async (_: void, { getState, dispatch }) => {
        const {
            accountReducer: { loginRequestStatus },
        } = getState() as TAppState;
        if (loginRequestStatus === 'initial') {
            dispatch(setLoginRequestStatusAction('requested'));
        }
        const response = await getCurrentUserApiCall(getNetworkService());
        if (!response.success) {
            dispatch(setLoginRequestStatusAction('error'));
        } else {
            const user = response.data as IUser;
            dispatch(setUserAction(user));
            await dispatch(getUserSettingsThunkAction());
            await dispatch(loadLampModelsThunkAction());
            dispatch(setLoginRequestStatusAction('success'));
        }
    },
);
