import { useEffect, useState } from 'react';
import { LampMenuConnected } from './LampMenu';
import { SupplyStationMenuConnected } from './SupplyStationMenu';
import { IDispatchProps, IStateProps } from './types';

export const PlacemarkMenu: React.FC<IStateProps & IDispatchProps> = ({
    currentPlacemarkData,
    currentPlacemarkType,
    user,
    resetCurrentPlacemark,
}) => {
    const [currentType, setCurrentType] = useState(currentPlacemarkType);

    useEffect(() => {
        setCurrentType(currentPlacemarkType);
    }, [currentPlacemarkData]);

    const handleEscapeClick = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            resetCurrentPlacemark();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeClick);
        return () => {
            document.removeEventListener('keydown', handleEscapeClick);
        };
    }, []);

    return (
        <>
            {currentType === 'Lamp' && (
                <LampMenuConnected
                    allowWrite={user?.role !== 'r'}
                    handleTypeChange={(value) => setCurrentType(value)}
                    placemarkData={currentPlacemarkData}
                    type={currentType}
                />
            )}

            {currentType === 'Supply station' && currentPlacemarkData && (
                <SupplyStationMenuConnected
                    allowWrite={user?.role !== 'r'}
                    handleTypeChange={(value) => setCurrentType(value)}
                    placemarkData={currentPlacemarkData}
                    type={currentType}
                />
            )}
        </>
    );
};
