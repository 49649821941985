import { Input } from 'components/common/Input';
import { Form, FormRenderProps } from 'react-final-form';
import styles from './AreaMenu.module.scss';
import { IDispatchProps, IFormRenderProps, IStateProps } from './types';
import { getInitialValues } from './utils';

export const AreaMenu: React.FC<IStateProps & IDispatchProps> = ({
    currentArea,
    currentPlacemark,
    resetCurrentArea,
    addArea,
    modifyArea,
    deleteArea,
}) => {
    const onSubmit = async (values: IFormRenderProps, form: any) => {
        let result;
        if (values?.action === 'submit') {
            delete values['action'];
            if (!currentArea) {
                result = await addArea({ color: values.color, name: values.name });
            } else {
                result = await modifyArea({ color: values.color, name: values.name });
            }
        } else if (values?.action === 'delete') {
            await deleteArea();
        }
        if (!result.payload) {
            form.reset();
        }
    };

    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        if (values.action === 'submit') {
            if (!values.name || values.name === '') {
                errors.name = 'Error';
            }
        }
        return errors;
    };

    const render = ({ handleSubmit, form, dirty }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={`${styles.form} container`}>
            <div className='row mb-1'>
                <label htmlFor='nameId' className='col-sm-3 col-form-label col-form-label-sm'>
                    Название
                </label>
                <div className='col-sm-9'>
                    <Input
                        name='name'
                        className='form-control form-control-sm'
                        placeholder='Имя'
                        id='nameId'
                    />
                </div>
            </div>
            <div className='row mb-1'>
                <label htmlFor='nameId' className='col-sm-3 col-form-label col-form-label-sm'>
                    Цвет
                </label>
                <div className='col-sm-9'>
                    <Input
                        type='color'
                        className='form-control form-control-color'
                        id='exampleColorInput'
                        name='color'
                    />
                </div>
            </div>
            <div className={`${styles.buttons} mt-3`}>
                {dirty && (
                    <button
                        type='submit'
                        className='btn btn-success btn-sm'
                        onClick={() => form.change('action', 'submit')}>
                        Сохранить
                    </button>
                )}
                {currentArea && (
                    <button
                        type='submit'
                        className='btn btn-danger btn-sm'
                        onClick={() => form.change('action', 'delete')}>
                        Удалить
                    </button>
                )}
                {currentPlacemark && (
                    <button
                        onClick={() => {
                            form.change('action', 'another');
                            resetCurrentArea();
                        }}
                        className='btn btn-secondary btn-sm'>
                        Отмена
                    </button>
                )}
            </div>
        </form>
    );

    return (
        <Form
            onSubmit={onSubmit}
            render={render}
            initialValues={getInitialValues(currentArea)}
            validate={validate}
        />
    );
};
