import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLampsAction } from 'actions/lamps/setLampsAction';
import { setSupplyStationsAction } from 'actions/supplyStations/setSupplyStationsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getLampsApiCall } from 'api/lamps/getLampsApiCall';
import { getSupplyStationsApiCall } from 'api/supplyStations/getSupplyStationsApiCall';
import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { IFeatureCollection } from 'types/feature/IFeatureCollection';
import { ILamp } from 'types/ILamp';
import { ISupplyStation } from 'types/ISupplyStation';
import { TAppState } from 'types/TAppState';
import { getLampColor } from 'utils/getLampColor';
import { featureClickEventThunkAction } from './featureClickEventThunkAction';

export const loadFeatureThunkAction = createAsyncThunk(
    'loadFeatureCollection',
    async (_: void, { dispatch, getState }) => {
        const {
            mapReducer: { map, objectManager },
            lampModelsReducer: { lampModels },
        } = getState() as TAppState;

        const featureCollection: IFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        let response = await getLampsApiCall(getNetworkService());
        if (response.success) {
            const result = response.data as { [id: string]: any }[];
            const lamps: ILamp[] = [];
            for (const item of result) {
                const lamp = item as ILamp;
                lamps.push(lamp);
                const {
                    id,
                    coordinates,
                    model,
                    placement,
                    power,
                    supply_station,
                    height,
                    surface,
                } = lamp;
                const color = getLampColor(lamp, lampModels);
                featureCollection.features.push({
                    id,
                    geometry: { type: 'Point', coordinates },
                    type: 'Feature',
                    options: { preset: EPlacemarkTypes.LAMP, iconColor: color },
                    properties: {
                        type: 'Lamp',
                        supply_station,
                        placement,
                        model,
                        power: power.toString(),
                        id,
                        height: height ? height.toString() : '',
                        surface,
                    },
                });
            }
            dispatch(setLampsAction(lamps));
        }
        response = await getSupplyStationsApiCall(getNetworkService());
        if (response.success) {
            const result = response.data as { [id: string]: any }[];
            for (const item of result) {
                const supplyStation = item as ISupplyStation;
                const { id, coordinates, cabinet, organization } = supplyStation;
                featureCollection.features.push({
                    id,
                    geometry: { type: 'Point', coordinates },
                    type: 'Feature',
                    options: {
                        preset: EPlacemarkTypes.SUPPLY_STATION,
                        iconColor: EPlacemarkColors.SUPPLY_STATION,
                    },
                    properties: {
                        type: 'Supply station',
                        organization,
                        cabinet,
                        iconContent: supplyStation.name,
                        id,
                    },
                });
            }
            dispatch(setSupplyStationsAction(result as ISupplyStation[]));
        }
        objectManager.add(featureCollection);
        objectManager.objects.events.add(['click'], (e: any) =>
            dispatch(featureClickEventThunkAction(e)),
        );
        map.geoObjects.add(objectManager);
    },
);
