import { createReducer } from '@reduxjs/toolkit';
import { resetLineReducerAction } from 'actions/line/resetLineReducerAction';
import { setLineAction } from 'actions/line/setLineAction';
import { setLineCountAction } from 'actions/line/setLineCountAction';
import { setLinePointsAction } from 'actions/line/setLinePointsAction';
import { setPointsAction } from 'actions/line/setPointsAction';
import { IPlacemark } from 'types/IPlacemark';

interface ILineInitialState {
    linePoints: [IPlacemark | null, IPlacemark | null];
    points: IPlacemark[];
    count: number;
    line: null | IPlacemark;
}

const lineReducerInitialState: ILineInitialState = {
    linePoints: [null, null],
    points: [],
    count: 3,
    line: null,
};

export const lineReducer = createReducer(lineReducerInitialState, (builder) =>
    builder
        .addCase(resetLineReducerAction, () => {
            return lineReducerInitialState;
        })
        .addCase(setLinePointsAction, (state, { payload: linePoints }) => {
            state.linePoints = linePoints;
        })
        .addCase(setPointsAction, (state, { payload: points }) => {
            state.points = points;
        })
        .addCase(setLineCountAction, (state, { payload: count }) => {
            state.count = count;
        })
        .addCase(setLineAction, (state, { payload: line }) => {
            state.line = line;
        }),
);
