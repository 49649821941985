import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAreasAction } from 'actions/areas/setAreasAction';
import { getAreasApiCall } from 'api/areas/getAreasApiCall';
import { getNetworkService } from 'api/getNetworkService';

export const getAreasThunkAction = createAsyncThunk('getAreas', async (_: void, { dispatch }) => {
    const response = await getAreasApiCall(getNetworkService());
    if (response.success) {
        const areas = response.data;
        dispatch(setAreasAction(areas));
    }
});
