import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLampModelsAction } from 'actions/lampModels/setLampModelsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getLampModelsApiCall } from 'api/lampModels/getLampModelsApiCall';
import { getAreasThunkAction } from 'thunks/areas/getAreasThunkAction';
import { ILampModel } from 'types/ILampModel';
import { getLampModelsPermissionsThunkAction } from './getLampModelsPermissionsThunkAction';

export const loadLampModelsThunkAction = createAsyncThunk(
    'loadLampModels',
    async (_: void, { dispatch }) => {
        const response = await getLampModelsApiCall(getNetworkService());
        if (response.success) {
            const result = response.data as ILampModel[];
            const models = result.map((model: any) => {
                if (!model.areas) {
                    return { ...model, areas: [] };
                }
                return model;
            });
            dispatch(setLampModelsAction(models));
            await dispatch(getLampModelsPermissionsThunkAction());
            await dispatch(getAreasThunkAction());
        }
    },
);
