import { createAsyncThunk } from '@reduxjs/toolkit';
import { addLampModelAction } from 'actions/lampModels/addLampModelAction';
import { getNetworkService } from 'api/getNetworkService';
import { saveLampModelApiCall } from 'api/lampModels/saveLampModelApiCall';
import { ILampModel } from 'types/ILampModel';

export const addLampModelThunkAction = createAsyncThunk<void | string, ILampModel>(
    'addLampModel',
    async (newLampType, { dispatch }) => {
        const response = await saveLampModelApiCall(getNetworkService(), newLampType);
        if (response.success) {
            dispatch(addLampModelAction(newLampType));
        } else {
            return 'error';
        }
    },
);
