import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetAreasReducerAction } from 'actions/areas/resetAreasReducerAction';
import { TAppState } from 'types/TAppState';

export const resetAreasStoreThunkAction = createAsyncThunk(
    'resetAreasStore',
    (_: void, { dispatch, getState }) => {
        const {
            mapReducer: { map },
            areasReducer: { geoCollection },
        } = getState() as TAppState;
        if (geoCollection) {
            map.geoObjects.remove(geoCollection);
            dispatch(resetAreasReducerAction());
        }
    },
);
