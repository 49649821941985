import { AreaMenuConnected } from 'components/AreaMenu';
import { MapMenuConnected } from 'components/MapMenu';
import { PlacemarkMenuConnected } from 'components/PlacemarkMenuNew';
import { useEffect } from 'react';
import styles from './Map.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const Map: React.FC<IStateProps & IDispatchProps> = ({
    currentPlacemarkData,
    currentArea,
    mapMode,
    map,
    lampModels,
    initMap,
    destroyMap,
}) => {
    useEffect(() => {
        return destroyMap;
    }, []);

    useEffect(() => {
        if (!map && lampModels.length !== 0) {
            ymaps.ready({ require: ['ext.paintOnMap'], successCallback: initMap });
        }
    }, [lampModels, map]);

    return (
        <div id='map' className={styles.map}>
            <MapMenuConnected />
            {(currentPlacemarkData || mapMode === 'line' || mapMode === 'select') && (
                <PlacemarkMenuConnected />
            )}
            {currentArea && <AreaMenuConnected />}
        </div>
    );
};
