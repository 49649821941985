import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { addSupplyStationAction } from 'actions/supplyStations/addSupplyStationAction';
import { getNetworkService } from 'api/getNetworkService';
import { saveSupplyStationsApiCall } from 'api/supplyStations/saveSupplyStationsApiCall';
import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { ISupplyStation } from 'types/ISupplyStation';
import { TAppState } from 'types/TAppState';
import { setPlacemarkColor } from 'utils/placemark/setPlacemarkColor';
import { setPlacemarkIcon } from 'utils/placemark/setPlacemarkIcon';
import { setPlacemarkProperty } from 'utils/placemarkProperties/setPlacemarkProperty';

export const addSupplyStationThunkAction = createAsyncThunk<
    Promise<undefined | string>,
    ISupplyStation
>('addSupplyStation', async (supplyStation: ISupplyStation, { dispatch, getState }) => {
    const {
        mapReducer: { currentPlacemarkData },
    } = getState() as TAppState;
    const response = await saveSupplyStationsApiCall(getNetworkService(), [supplyStation]);
    if (!response.success) {
        dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        return response.statusText;
    }
    dispatch(addSupplyStationAction(supplyStation));
    dispatch(showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }));
    if (currentPlacemarkData?.placemark) {
        setPlacemarkColor(currentPlacemarkData?.placemark, EPlacemarkColors.SELECTED);
        setPlacemarkIcon(currentPlacemarkData?.placemark, EPlacemarkTypes.SUPPLY_STATION);
        setPlacemarkProperty(currentPlacemarkData?.placemark, { iconContent: supplyStation.name });
        dispatch(
            setCurrentPlacemarkAction({ placemark: currentPlacemarkData.placemark, saved: true }),
        );
    }
});
