import { connect } from 'react-redux';
import { loginThunkAction } from 'thunks/account/loginThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { LoginPage } from './LoginPage';
import { IDispatchProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    login: (data) => dispatch(loginThunkAction(data)),
});

export const LoginPageConnected = connect(null, mapDispatchToProps)(LoginPage);
