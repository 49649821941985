/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
export const log = {
    debug: console.debug.bind(console),
    error: console.error.bind(console),
    info: console.info.bind(console),
    log: console.log.bind(console),
    table: console.table.bind(console),
    trace: console.trace.bind(console),
};
