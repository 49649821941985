import { createAction } from '@reduxjs/toolkit';
import { EAreasActions } from 'constants/actions/EAreasActions';
import { IArea } from 'types/IArea';

interface IPayload {
    id: string;
    fields: Partial<IArea>;
}

export const modifyAreaAction = createAction<IPayload>(EAreasActions.MODIFY_AREA);
