import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyAreaAction } from 'actions/areas/modifyAreaAction';
import { modifyAreaApiCall } from 'api/areas/modifyAreaApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { IArea } from 'types/IArea';
import { TAppState } from 'types/TAppState';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const modifyAreaThunkAction = createAsyncThunk(
    'modifyArea',
    async (fields: Partial<IArea>, { getState, dispatch }) => {
        const {
            areasReducer: { currentArea },
        } = getState() as TAppState;
        if (currentArea) {
            const id = getPlacemarkProperty(currentArea, 'id');
            const result = await modifyAreaApiCall(getNetworkService(), { fields, id });
            if (!result.success) {
                dispatch(
                    showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }),
                );
                return result.statusText;
            }
            dispatch(modifyAreaAction({ id, fields }));
            dispatch(
                showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }),
            );
        }
    },
);
