import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilterAction } from 'actions/map/setFilterAction';
import { setGeoQueryAction } from 'actions/select/setGeoQueryAction';
import { TAppState } from 'types/TAppState';

interface IPayload {
    type: string;
    field: { [id: string]: string | string[] };
    replace?: boolean;
}

export const setFilterThunkAction = createAsyncThunk<void, IPayload | undefined>(
    'setFilter',
    async (newFilter, { getState, dispatch }) => {
        const {
            selectReducer: { geoQuery },
        } = getState() as TAppState;
        if (!newFilter) {
            dispatch(setFilterAction({}));
        } else {
            if (newFilter.replace) {
                dispatch(setFilterAction({ [newFilter.type]: newFilter.field }));
            }
        }
        if (geoQuery) {
            const {
                mapReducer: { objectManager },
            } = getState() as TAppState;
            dispatch(setGeoQueryAction(ymaps.geoQuery(objectManager.objects)));
        }
    },
);
