import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteLampAction } from 'actions/lamps/deleteLampAction';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { getNetworkService } from 'api/getNetworkService';
import { deleteLampApiCall } from 'api/lamps/deleteLampApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';

interface IPayload {
    placemark: any;
    id: string;
}

export const deleteLampThunkAction = createAsyncThunk<undefined | string, IPayload>(
    'deleteLamp',
    async ({ id, placemark }, { getState, dispatch }) => {
        const {
            mapReducer: { map },
        } = getState() as TAppState;
        const response = await deleteLampApiCall(getNetworkService(), id);
        if (!response.success) {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
            return response.statusText;
        }
        dispatch(deleteLampAction(id));
        map.geoObjects.remove(placemark);
        dispatch(setCurrentPlacemarkAction(null));
        dispatch(showNotificationThunkAction({ text: 'Удалена', timeout: 3000, type: 'success' }));
    },
);
