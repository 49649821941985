import { connect } from 'react-redux';
import { loadLampModelsThunkAction } from 'thunks/lampModels/loadLampModelsThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LampModelsPage } from './LampModelsPage';
import { IDispatchProps, IStateProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    load: () => dispatch(loadLampModelsThunkAction()),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    lampModels: state.lampModelsReducer.lampModels,
});

export const LampModelsPageConnected = connect(mapStateToProps, mapDispatchToProps)(LampModelsPage);
