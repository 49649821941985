import { INetworkService } from 'types/api/INetworkService';
import { ILamp } from 'types/ILamp';

interface IPayload {
    id: string;
    fields: Partial<ILamp>;
}

export const modifyLampsApiCall = (networkService: INetworkService, data: IPayload[]) =>
    networkService.put({ url: '/api/lamps/', data });
