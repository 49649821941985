import { createAction } from '@reduxjs/toolkit';
import { EUsersActions } from 'constants/actions/EUsersActions';
import { IUser } from 'types/IUser';

interface IPayload {
    id: string;
    fields: Partial<IUser>;
}

export const modifyUserAction = createAction<IPayload>(EUsersActions.MODIFY_USER);
