import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUsersAction } from 'actions/users/setUsersAction';
import { getNetworkService } from 'api/getNetworkService';
import { addUserApiCall } from 'api/users/addUserApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { IUser } from 'types/IUser';
import { TAppState } from 'types/TAppState';

export const addUserThunkAction = createAsyncThunk<Promise<undefined | string>, IUser>(
    'addUser',
    async (user, { dispatch, getState }) => {
        const {
            usersReducer: { users },
        } = getState() as TAppState;
        const response = await addUserApiCall(getNetworkService(), user);
        if (!response.success) {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
            return response.statusText;
        }
        dispatch(setUsersAction([...users, { ...user, id: response.data.id }]));
        dispatch(showNotificationThunkAction({ text: 'Сохранен', timeout: 3000, type: 'success' }));
    },
);
