import { Select } from '../Select';
import { IOwnProps, IStateProps } from './types';

export const LampModelsSelect: React.FC<IOwnProps & IStateProps> = ({
    lampModels,
    valueId,
    name,
    disabled,
}) => {
    const selectedValueTemp = lampModels.find((item) => item.id === valueId);
    let selectedValue = 'blanc';
    if (selectedValueTemp) selectedValue = selectedValueTemp.name;
    return (
        <Select
            name={name}
            disabled={disabled}
            value={selectedValue}
            list={lampModels.map((item) => item.name)}
            format={(value: string) => lampModels.find((item) => item.id === value)?.name as string}
            parse={(value: string) => lampModels.find((item) => item.name === value)?.id as string}
        />
    );
};
