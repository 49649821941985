import { Input } from 'components/common/Input';
import { Select } from 'components/common/Select';
import { useEffect } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { TRole } from 'types/TRole';
import { TRoleString } from 'types/TRoleString';
import { convertRoleToString } from 'utils/roles/convertRoleToString';
import { convertStringToRole } from 'utils/roles/convertStringToRole';
import { IDispatchProps, IFormRenderProps, IStateProps } from './types';
import styles from './UserPage.module.scss';

export const UserPage: React.FC<IStateProps & IDispatchProps> = ({
    user,
    users,
    areas,
    modify,
    save,
    del,
    loadUsers,
}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (users.length === 0) {
            loadUsers();
        }
    }, []);

    const onValidate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        for (const value in values) {
            if (!value) {
                errors[value] = 'Error';
            }
        }
        return errors;
    };

    const onSubmit = async (values: IFormRenderProps) => {
        if (values.action === 'submit') {
            delete values['action'];
            let error;
            if (user) {
                error = await modify({ id: user.id, fields: values });
            } else {
                error = await save(values);
            }
            if (error.payload) {
                return { error: true };
            }
        } else if (values.action === 'delete') {
            const error = await del(user?.id as string);
            if (error.payload) {
                return { error: true };
            }
        }
        navigate('/users');
    };

    const roles: TRole[] = ['admin', 'r', 'rw'];

    const render = ({ handleSubmit, values, form }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={`${styles.form} `}>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                    Имя
                </label>
                <div className='col-sm-8'>
                    <Input name='first_name' placeholder='Имя' className='form-control' />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                    Фамилия
                </label>
                <div className='col-sm-8'>
                    <Input name='last_name' placeholder='Фамилия' className='form-control' />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                    Логин
                </label>
                <div className='col-sm-8'>
                    <Input name='login' placeholder='Логин' className='form-control' />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                    Пароль
                </label>
                <div className='col-sm-8'>
                    <Input name='password' placeholder='Пароль' className='form-control' />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                    Роль
                </label>
                <div className='col-sm-8'>
                    <Select
                        list={roles.map((role) => convertRoleToString(role))}
                        name='role'
                        value={convertRoleToString(values.role)}
                        parse={(value) => convertStringToRole(value as TRoleString)}
                        format={(value) => convertRoleToString(value as TRole)}
                    />
                </div>
            </div>
            {areas.length !== 0 && (
                <div className='row mb-3'>
                    <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                        Ограничения
                    </label>
                    <div className='col-sm-8'>
                        <ul className='list-group'>
                            {areas.map((area) => (
                                <li className='list-group-item' key={area.id}>
                                    <input
                                        className='form-check-input me-1'
                                        type='checkbox'
                                        value=''
                                        aria-label='...'
                                        checked={
                                            values.areas.find((e) => e === area.id) !== undefined
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                form.change('areas', [...values.areas, area.id]);
                                            } else {
                                                form.change(
                                                    'areas',
                                                    values.areas.filter((a) => a !== area.id),
                                                );
                                            }
                                        }}
                                    />
                                    {area.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className={styles.buttons}>
                <button
                    onClick={() => form.change('action', 'submit')}
                    type='submit'
                    className='btn btn-success'>
                    {user ? 'Сохранить' : 'Добавить'}
                </button>
                {user && (
                    <button
                        onClick={() => form.change('action', 'delete')}
                        type='submit'
                        className='btn btn-outline-danger'>
                        Удалить
                    </button>
                )}
                <button
                    onClick={() => form.change('action', 'another')}
                    className='btn btn-outline-secondary'>
                    Отмена
                </button>
            </div>
        </form>
    );

    const wrapperStyle = `container-xl ${styles.wrapper}`;
    return (
        <div className={wrapperStyle}>
            {user && <h4>Редактирование {user.login}</h4>}
            {!user && <h4>Добавление нового пользователя</h4>}
            <Form
                onSubmit={onSubmit}
                render={render}
                validate={onValidate}
                initialValues={
                    { ...user, password: '', areas: user?.areas || [], role: user?.role || 'r' } ||
                    undefined
                }
            />
        </div>
    );
};
