import { createReducer } from '@reduxjs/toolkit';
import { addAreaAction } from 'actions/areas/addAreaAction';
import { deleteAreaAction } from 'actions/areas/deleteAreaAction';
import { modifyAreaAction } from 'actions/areas/modifyAreaAction';
import { resetAreasReducerAction } from 'actions/areas/resetAreasReducerAction';
import { setAreasAction } from 'actions/areas/setAreasAction';
import { setCurrentAreaAction } from 'actions/areas/setCurrentAreaAction';
import { setGeoCollectionAction } from 'actions/areas/setGeoCollectionAction';
import { IArea } from 'types/IArea';
import { IPlacemark } from 'types/IPlacemark';

interface IAreasReducerInitialState {
    areas: IArea[];
    currentArea: IPlacemark | null;
    geoCollection: any | null;
}

const areasInitialState: IAreasReducerInitialState = {
    areas: [],
    currentArea: null,
    geoCollection: null,
};

export const areasReducer = createReducer(areasInitialState, (builder) =>
    builder
        .addCase(resetAreasReducerAction, () => areasInitialState)
        .addCase(setGeoCollectionAction, (state, { payload: geoCollection }) => {
            state.geoCollection = geoCollection;
        })
        .addCase(setAreasAction, (state, { payload: areas }) => {
            state.areas = areas;
        })
        .addCase(addAreaAction, (state, { payload: area }) => {
            state.areas.push(area);
        })
        .addCase(modifyAreaAction, (state, { payload: { id, fields } }) => {
            state.areas.map((area) => {
                if (area.id === id) {
                    area = { ...area, ...fields };
                }
                return area;
            });
        })
        .addCase(deleteAreaAction, (state, { payload: id }) => {
            state.areas = state.areas.filter((area) => area.id !== id);
            state.currentArea = null;
        })
        .addCase(setCurrentAreaAction, (state, { payload: area }) => {
            state.currentArea = area;
        }),
);
