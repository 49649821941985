import { connect } from 'react-redux';
import { getCurrentLampModelSelector } from 'selectors/getCurrentLampModelSelector';
import { addLampModelThunkAction } from 'thunks/lampModels/addLampModelThunkAction';
import { deleteLampModelThunkAction } from 'thunks/lampModels/deleteLampModelThunkAction';
import { modifyLampModelThunkAction } from 'thunks/lampModels/modifyLampModelThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LampModelPage } from './LampModelPage';
import { IDispatchProps, IStateProps } from './types';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    modify: (data) => dispatch(modifyLampModelThunkAction(data)),
    save: (lampType) => dispatch(addLampModelThunkAction(lampType)),
    del: (id) => dispatch(deleteLampModelThunkAction(id)),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentLampModel: getCurrentLampModelSelector(state),
    areas: state.areasReducer.areas,
});

export const LampModelPageConnected = connect(mapStateToProps, mapDispatchToProps)(LampModelPage);
