import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';
import { setUserSettingsThunkAction } from './setUserSettingsThunkAction';

export const setUserMapSettingsThunkAction = createAsyncThunk(
    'setUserMapSettings',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map },
        } = getState() as TAppState;
        const center = map.getCenter();
        const zoom = map.getZoom();
        const { payload: error } = await dispatch(
            setUserSettingsThunkAction({ mapCenter: center, mapZoom: zoom }),
        );
        if (!error) {
            dispatch(
                showNotificationThunkAction({
                    text: 'Настройки сохранены',
                    timeout: 3000,
                    type: 'success',
                }),
            );
        } else {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        }
    },
);
