import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteLampModelAction } from 'actions/lampModels/deleteLampModelAction';
import { getNetworkService } from 'api/getNetworkService';
import { deleteLampModelApiCall } from 'api/lampModels/deleteLampModelApiCall';

export const deleteLampModelThunkAction = createAsyncThunk<void | string, string>(
    'deleteLampModel',
    async (id, { dispatch }) => {
        const response = await deleteLampModelApiCall(getNetworkService(), id);
        if (response.success) {
            dispatch(deleteLampModelAction(id));
        } else {
            return 'error';
        }
    },
);
