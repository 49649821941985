import { ILamp } from 'types/ILamp';
import { IPlacemarkData } from 'types/IPlacemarkData';
import { ISupplyStation } from 'types/ISupplyStation';
import { TMapMode } from 'types/TMapMode';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';
import { IFormRenderProps } from './types';

export const getInitialFieldsNew = (
    mode: TMapMode,
    placemarkData: IPlacemarkData | null,
    currentLamp: ILamp | null,
    prevValues?: any,
): Partial<IFormRenderProps> => {
    if (placemarkData) {
        let address = getPlacemarkProperty(placemarkData.placemark, 'address');
        if (placemarkData.saved && currentLamp) {
            address ||= currentLamp.address;
        }
        const values = { ...prevValues.current, ...currentLamp, address };
        prevValues.current = values;
        return values;
    } else {
        if (mode === 'line') {
            return { ...prevValues.current };
        }
        prevValues.current = {};
        return { power: 0 };
    }
};

export const getDataToSend = (currentLamp: ILamp, values: IFormRenderProps) => {
    const fields: { [id: string]: any } = {};
    for (const key in values) {
        const keyType = key as keyof ILamp;
        const keyType2 = key as keyof IFormRenderProps;
        if (
            currentLamp[keyType] !== values[keyType2] &&
            keyType2 !== 'id' &&
            keyType2 !== 'rerender'
        ) {
            fields[keyType2] = values[keyType2];
        }
    }
    return fields;
};

export const getLampFields = () => [
    'placement',
    'model',
    'supply_station',
    'address',
    'surface',
    'height',
];

export const getLampSupplyStation = (supplyStations: {
    [id: string]: ISupplyStation;
}): string[] => {
    const list: string[] = [];
    for (const id in supplyStations) {
        list.push(supplyStations[id].name);
    }
    return list;
};
