import { connect } from 'react-redux';
import { getUsersThunkAction } from 'thunks/users/getUsersThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { IDispatchProps, IStateProps } from './types';
import { UsersPage } from './UsersPage';

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getUsers: () => dispatch(getUsersThunkAction()),
});

const mapStateToProps = (state: TAppState): IStateProps => ({
    users: state.usersReducer.users,
});

export const UsersPageConnected = connect(mapStateToProps, mapDispatchToProps)(UsersPage);
