import { ILamp } from 'types/ILamp';
import { TAppState } from 'types/TAppState';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const getCurrentLampSelector = (state: TAppState): ILamp | null => {
    const placemarkData = state.mapReducer.currentPlacemarkData;
    if (!placemarkData) {
        return null;
    }
    const id = getPlacemarkProperty(placemarkData.placemark, 'id') as string;
    return state.lampsReducer.lamps[id] || null;
};
