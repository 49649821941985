import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAreaAction } from 'actions/areas/deleteAreaAction';
import { deleteAreaApiCall } from 'api/areas/deleteAreaApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const deleteAreaThunkAction = createAsyncThunk(
    'deleteArea',
    async (_: void, { dispatch, getState }) => {
        const {
            areasReducer: { currentArea, geoCollection },
        } = getState() as TAppState;
        if (currentArea) {
            const id = getPlacemarkProperty(currentArea, 'id');
            const result = await deleteAreaApiCall(getNetworkService(), id);
            if (!result.success) {
                dispatch(
                    showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }),
                );
                return result.statusText;
            }
            geoCollection.remove(currentArea);
            dispatch(deleteAreaAction(id));
            dispatch(
                showNotificationThunkAction({ text: 'Удалена', timeout: 3000, type: 'success' }),
            );
        }
    },
);
