import { useEffect, useRef, useState } from 'react';
import styles from './Notification.module.scss';
import { IStateProps } from './types';

export const Notification: React.FC<IStateProps> = ({ notificationToShow }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);
    const currentComponentRef = useRef(null);

    useEffect(() => {
        if (!notificationToShow) {
            setIsFadingOut(false);
        }
    }, [notificationToShow]);

    const animationFadeOutEnd = () => {
        if (notificationToShow?.timeout) {
            setTimeout(() => {
                if (currentComponentRef.current) setIsFadingOut(true);
            }, notificationToShow?.timeout - 400);
        }
    };

    let style = '';
    if (notificationToShow?.type === 'error') {
        style = styles.wrapper_error;
    } else if (notificationToShow?.type === 'success') {
        style = styles.wrapper_success;
    }
    style = isFadingOut ? `${style} ${styles.fadeOutAnimation}` : style;

    return (
        notificationToShow && (
            <div
                className={`${style} ${styles.wrapper}`}
                ref={currentComponentRef}
                onAnimationEnd={animationFadeOutEnd}>
                {notificationToShow.text}
            </div>
        )
    );
};
