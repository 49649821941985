import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/dropdown';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { appStore } from 'store/appStore';
import { setLampsColors } from 'utils/lampColor';
import { App } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

setLampsColors();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={appStore}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
