import { connect } from 'react-redux';
import { getCurrentUserThunkAction } from 'thunks/account/getCurrentUserThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LoginChecker } from './LoginChecker';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    loginRequestStatus: state.accountReducer.loginRequestStatus,
    user: state.accountReducer.user,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    getCurrentUser: () => dispatch(getCurrentUserThunkAction()),
});

export const LoginCheckerConnected = connect(mapStateToProps, mapDispatchToProps)(LoginChecker);
