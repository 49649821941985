import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { getUUID } from 'utils/getUUID';

export const getNewPlacemark = ({
    id,
    coordinates,
    color,
    preset,
    draggable = false,
}: {
    id?: string;
    coordinates: number[];
    color?: string;
    preset: string;
    draggable?: boolean;
}) => {
    return new ymaps.Placemark(
        coordinates,
        // { ...getPlacemarkPropertiesTemplate(properties) },
        { id: id ? id : getUUID() },
        {
            preset, //'islands#icon',
            iconColor: color || EPlacemarkColors.TEMP,
            draggable,
        },
    );
};
