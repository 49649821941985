import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyLampsAction } from 'actions/lamps/modifyLampsAction';
import { getNetworkService } from 'api/getNetworkService';
import { modifyLampsApiCall } from 'api/lamps/modifyLampsApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { ILamp } from 'types/ILamp';

export const modifyLampThunkAction = createAsyncThunk<
    undefined | string,
    { id: string; fields: Partial<Omit<ILamp, 'id'>> }[]
>('modifyLamp', async (data, { dispatch }) => {
    const response = await modifyLampsApiCall(getNetworkService(), data);
    if (!response.success) {
        dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        return response.statusText;
    }
    dispatch(showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }));
    dispatch(modifyLampsAction(data));
});
