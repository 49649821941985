import { connect } from 'react-redux';
import { TAppState } from 'types/TAppState';
import { ExportPage } from './ExportPage';
import { IStateProps } from './types';

export const mapStateToProps = (state: TAppState): IStateProps => ({
    areas: state.areasReducer.areas,
});

export const ExportPageConnected = connect(mapStateToProps)(ExportPage);
