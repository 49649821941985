import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUsersAction } from 'actions/users/setUsersAction';
import { getNetworkService } from 'api/getNetworkService';
import { getUsersApiCall } from 'api/users/getUsersApiCall';
import { getAreasThunkAction } from 'thunks/areas/getAreasThunkAction';

export const getUsersThunkAction = createAsyncThunk('getUsers', async (_: void, { dispatch }) => {
    const response = await getUsersApiCall(getNetworkService());
    if (response.success) {
        const users = response.data.map((user: any) => {
            if (!user.areas) {
                return { ...user, areas: [] };
            }
            return user;
        });
        dispatch(setUsersAction(users));
        await dispatch(getAreasThunkAction());
    }
});
