import { IArea } from 'types/IArea';
import { TAppState } from 'types/TAppState';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const getCurrentAreaSelector = (state: TAppState): null | IArea => {
    const currentArea = state.areasReducer.currentArea;
    if (!currentArea) return null;
    const id = getPlacemarkProperty(currentArea, 'id');
    const foundArea = state.areasReducer.areas.find((value) => value.id === id);
    return foundArea ?? null;
};
