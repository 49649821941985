import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IUser } from 'types/IUser';
import { convertRoleToString } from 'utils/roles/convertRoleToString';
import { IDispatchProps, IStateProps } from './types';
import styles from './UsersPage.module.scss';

export const UsersPage: React.FC<IStateProps & IDispatchProps> = ({ getUsers, users }) => {
    useEffect(() => {
        if (users.length === 0) {
            getUsers();
        }
    }, []);

    const renderItem = (item: IUser, idx: number) => (
        <tr key={item.id}>
            <th scope='row'>{idx + 1}</th>
            <td className='col-3'>{convertRoleToString(item.role)}</td>
            <td className='col-4'>{item.first_name}</td>
            <td className='col-4'>{item.last_name}</td>
            <td className='col-4'>{item.login}</td>
            <td>
                <Link to={`/users/${item.id}`}>Редактировать</Link>
            </td>
        </tr>
    );

    const wrapperStyle = `container-xl ${styles.wrapper}`;

    return (
        <div className={wrapperStyle}>
            <Link to='/menu'>Назад</Link>
            <Link to='/users/new'>Добавить нового</Link>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>#</th>
                        <th scope='col-2'>Роль</th>
                        <th scope='col'>Имя</th>
                        <th scope='col'>Фамилия</th>
                        <th scope='col-4'>Логин</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((item, idx) => {
                        return renderItem(item, idx);
                    })}
                </tbody>
            </table>
        </div>
    );
};
