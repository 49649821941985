import { createReducer } from '@reduxjs/toolkit';
import { setLoginRequestStatusAction } from 'actions/account/setLoginRequestStatusAction';
import { setUserAction } from 'actions/account/setUserAction';
import { TRequestStatus } from 'types/api/TRequestStatus';
import { IUser } from 'types/IUser';

interface IAccountReducerInitialState {
    user: null | IUser;
    loginRequestStatus: TRequestStatus;
}

const accountReducerInitialState: IAccountReducerInitialState = {
    user: null,
    loginRequestStatus: 'initial',
};

export const accountReducer = createReducer(accountReducerInitialState, (builder) =>
    builder
        .addCase(setLoginRequestStatusAction, (state, { payload: status }) => {
            state.loginRequestStatus = status;
        })
        .addCase(setUserAction, (state, { payload: user }) => {
            state.user = user;
        }),
);
