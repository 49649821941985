import { createReducer } from '@reduxjs/toolkit';
import { resetUserSettingsReducerAction } from 'actions/userSettings/resetUserSettingsReducerAction';
import { setUserSettingsAction } from 'actions/userSettings/setUserSettingsAction';

interface IUserSettingsReducer {
    mapCenter: [number, number];
    mapZoom: number;
}

const userSettingsReducerInitialState: IUserSettingsReducer = {
    mapCenter: [0, 0],
    mapZoom: 0,
};

export const settingsReducer = createReducer(userSettingsReducerInitialState, (builder) =>
    builder
        .addCase(resetUserSettingsReducerAction, () => userSettingsReducerInitialState)
        .addCase(setUserSettingsAction, (state, { payload: settings }) => {
            return { ...settings };
        }),
);
