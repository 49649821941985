import { TAppState } from 'types/TAppState';
import { TPlacemarkTypes } from 'types/TPlacemarkTypes';
import { getCurrentLampSelector } from './getCurrentLampSelector';
import { getCurrentSupplyStationSelector } from './getCurrentSupplyStationSelector';

export const getCurrentPlacemarkTypeSelector = (state: TAppState): TPlacemarkTypes | undefined => {
    const placemarkData = state.mapReducer.currentPlacemarkData;
    if (placemarkData) {
        const currentLamp = getCurrentLampSelector(state);
        const currentSupplyStation = getCurrentSupplyStationSelector(state);
        if (currentLamp) return 'Lamp';
        if (currentSupplyStation) return 'Supply station';
    }
    return 'Lamp';
};
