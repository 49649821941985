import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteLampAction } from 'actions/lamps/deleteLampAction';
import { resetSelectReducerAction } from 'actions/select/resetSelectReducerAction';
import { setGeoQueryAction } from 'actions/select/setGeoQueryAction';
import { getNetworkService } from 'api/getNetworkService';
import { deleteLampsApiCall } from 'api/lamps/deleteLampsApiCall';
import { batch } from 'react-redux';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';

export const deleteLampsInsideAreaThunkAction = createAsyncThunk<void | string, void>(
    'deleteLampsInsideArea',
    async (_, { getState, dispatch }) => {
        const {
            mapReducer: { objectManager, map },
            selectReducer: { selectedLamps },
            selectReducer: { area },
        } = getState() as TAppState;
        const ids = selectedLamps.map((lamp) => lamp.id);
        const response = await deleteLampsApiCall(getNetworkService(), ids);
        if (!response.success) {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
            return response.statusText;
        }
        batch(() => {
            ids.forEach((id) => dispatch(deleteLampAction(id)));
        });
        objectManager.remove(ids);
        dispatch(setGeoQueryAction(ymaps.geoQuery(objectManager.objects)));
        if (area) {
            map.geoObjects.remove(area);
            dispatch(resetSelectReducerAction());
        }
        dispatch(showNotificationThunkAction({ text: 'Удалены', timeout: 3000, type: 'success' }));
    },
);
