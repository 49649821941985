import { createReducer } from '@reduxjs/toolkit';
import { resetSelectReducerAction } from 'actions/select/resetSelectReducerAction';
import { setGeoQueryAction } from 'actions/select/setGeoQueryAction';
import { setSelectedAreaAction } from 'actions/select/setSelectedAreaAction';
import { setSelectedLampsAction } from 'actions/select/setSelectedLampsAction';
import { setSelectedSupplyStationsAction } from 'actions/select/setSelectedSupplyStationsAction';
import { ILamp } from 'types/ILamp';
import { ISupplyStation } from 'types/ISupplyStation';

interface ISelectReducer {
    selectedLamps: ILamp[];
    selectedSupplyStations: ISupplyStation[];
    area: any | null;
    geoQuery: any | null;
}

const selectReducerInitialState: ISelectReducer = {
    selectedLamps: [],
    selectedSupplyStations: [],
    area: null,
    geoQuery: null,
};

export const selectReducer = createReducer(selectReducerInitialState, (builder) =>
    builder
        .addCase(resetSelectReducerAction, () => selectReducerInitialState)
        .addCase(setSelectedAreaAction, (state, { payload: area }) => {
            state.area = area;
        })
        .addCase(setSelectedLampsAction, (state, { payload: selectedLamps }) => {
            state.selectedLamps = selectedLamps;
        })
        .addCase(setSelectedSupplyStationsAction, (state, { payload: selectedSupplyStations }) => {
            state.selectedSupplyStations = selectedSupplyStations;
        })
        .addCase(setGeoQueryAction, (state, { payload: geoQuery }) => {
            state.geoQuery = geoQuery;
        }),
);
