import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserSettingsAction } from 'actions/userSettings/setUserSettingsAction';
import { getNetworkService } from 'api/getNetworkService';
import { setUserSettingsApiCall } from 'api/userSettings/setUserSettingsApiCall';
import { TAppState } from 'types/TAppState';

export const setUserSettingsThunkAction = createAsyncThunk(
    'setUserSettings',
    async (
        settings: { [id: string]: any },
        { getState, dispatch },
    ): Promise<string | undefined> => {
        const { settingsReducer } = getState() as TAppState;
        const newSettings = { ...settingsReducer, ...settings };
        const response = await setUserSettingsApiCall(getNetworkService(), newSettings);
        if (!response.success) {
            return response.statusText;
        }
        dispatch(setUserSettingsAction(newSettings));
    },
);
