import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifyLampsAction } from 'actions/lamps/modifyLampsAction';
import { getNetworkService } from 'api/getNetworkService';
import { modifyLampsApiCall } from 'api/lamps/modifyLampsApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { ILamp } from 'types/ILamp';
import { TAppState } from 'types/TAppState';
import { getLampColor } from 'utils/getLampColor';

type TFields = Partial<Pick<ILamp, 'model' | 'name' | 'placement' | 'power' | 'supply_station'>>;

export const modifySelectedLampsThunkAction = createAsyncThunk(
    'modifySelectedLamps',
    async (fields: TFields, { getState, dispatch }) => {
        const {
            selectReducer: { selectedLamps },
            lampModelsReducer: { lampModels },
            mapReducer: { objectManager },
        } = getState() as TAppState;
        const dataToSend: {
            id: string;
            fields: TFields;
        }[] = [];
        let changeColor = false;
        for (const field in fields) {
            if (field === 'model') changeColor = true;
        }
        for (const lamp of selectedLamps) {
            dataToSend.push({ id: lamp.id, fields });
        }
        const result = await modifyLampsApiCall(getNetworkService(), dataToSend);
        if (result.success) {
            dispatch(modifyLampsAction(dataToSend));
            // FIX ME Не обновили properties
            if (selectedLamps && changeColor) {
                for (const lamp of selectedLamps) {
                    const color = getLampColor(
                        { ...lamp, model: fields['model'] as string },
                        lampModels,
                    );
                    objectManager.objects.setObjectOptions(lamp.id, {
                        iconColor: color,
                    });
                }
            }
            dispatch(
                showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }),
            );
        } else {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        }
    },
);
