import { LampModelsFilterConnected } from 'components/common/LampModelsFilter';
import { TMapMode } from 'types/TMapMode';
import { convertModeToString } from 'utils/modes/convertModeToString';
import styles from './MapMenu.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const MapMenu: React.FC<IStateProps & IDispatchProps> = ({
    mapMode,
    user,
    changeMapMode,
    saveMapSettings,
}) => {
    const list: TMapMode[] = ['point', 'select'];
    if (user?.role === 'rw') {
        list.push('line');
    } else if (user?.role === 'admin') {
        list.push('line');
        list.push('areas');
    }

    return (
        <div className={styles.menu}>
            <div className='mb-1'>
                <button className='btn btn-primary btn-sm' onClick={saveMapSettings}>
                    Сохранить расположение
                </button>
            </div>
            <div className='mb-1'>
                <label htmlFor='mapMode' className='form-label'>
                    Режим карты
                </label>
                <div>
                    <select
                        className='form-select form-select-sm'
                        onChange={(e) => changeMapMode(e.target.value as TMapMode)}
                        defaultValue={mapMode}>
                        {list.map((item) => (
                            <option value={item} key={item}>
                                {convertModeToString(item)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <LampModelsFilterConnected />
        </div>
    );
};
