import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifySupplyStationAction } from 'actions/supplyStations/modifySupplyStationAction';
import { getNetworkService } from 'api/getNetworkService';
import { updateSupplyStationsApiCall } from 'api/supplyStations/updateSupplyStationApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { ISupplyStation } from 'types/ISupplyStation';
import { TAppState } from 'types/TAppState';
import { setPlacemarkProperty } from 'utils/placemarkProperties/setPlacemarkProperty';

export const modifySupplyStationThunkAction = createAsyncThunk<
    Promise<undefined | string>,
    { id: string; fields: Partial<Omit<ISupplyStation, 'id'>> }
>('modifySupplyStation', async ({ fields, id }, { getState, dispatch }) => {
    const {
        mapReducer: { currentPlacemarkData },
    } = getState() as TAppState;
    const response = await updateSupplyStationsApiCall(getNetworkService(), [
        {
            id,
            fields,
        },
    ]);
    if (!response.success) {
        dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
        return response.statusText;
    }
    dispatch(showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }));
    dispatch(modifySupplyStationAction({ id, fields }));
    if (currentPlacemarkData && fields.name) {
        setPlacemarkProperty(currentPlacemarkData.placemark, {
            iconContent: fields.name,
        });
    }
});
