import { Input } from 'components/common/Input';
import { LampModelsSelectConnected } from 'components/common/LampsModelsSelect';
import { Select } from 'components/common/Select';
import { SupplyStationsSelect } from 'components/common/SupplyStationsSelect/SupplyStationsSelect';
import { LineCountMenuConnected } from 'components/LineCountMenu';
import { PointDeleteConfirmationDialog } from 'components/PointDeleteConfirmationDialog/PointDeleteConfirmationDialog';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { ILamp } from 'types/ILamp';
import { setDraggableEndCallback } from 'utils/draggableEndCallback';
import { getLampPlacements } from 'utils/lampProperties/getLampPlacements';
import { getLampSurfaces } from 'utils/lampProperties/getLampSurfaces';
import { getPlacemarkCoordinates } from 'utils/placemark/getPlacemarkCoordinates';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';
import styles from './LampMenu.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';
import { getDataToSend, getInitialFieldsNew, getLampFields } from './utils';

export const LampMenu: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    placemarkData,
    currentLamp,
    supplyStations,
    type,
    mode,
    lineIsSet,
    linePointsIsEmpty,
    selectLampsCount,
    selectSupplyStationsCount,
    allowWrite,
    lampModels,
    modifySelectedPoints,
    setPointByPolyline,
    resetCurrentPlacemark,
    addLamp,
    updateLamp,
    handleTypeChange,
    deleteLamp,
    saveLinePoints,
    deleteLampsInsideArea,
}) => {
    const prevValues = useRef({});

    let submit: undefined | any;
    const handleEnterClick = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (submit) submit();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEnterClick);
        return () => {
            document.removeEventListener('keydown', handleEnterClick);
            setDraggableEndCallback(() => {});
        };
    }, []);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        if (mode !== 'select') {
            let requiredFields = getLampFields();
            if (mode === 'line') {
                requiredFields = requiredFields.filter((item) => item !== 'address');
            }
            if (placemarkData) {
                const { saved } = placemarkData;
                if (saved) {
                    requiredFields = requiredFields.filter(
                        (item) => item !== 'height' && item !== 'surface',
                    );
                }
            }
            for (const field of requiredFields) {
                const fieldAsType = field as keyof IFormRenderProps;
                if (!values[fieldAsType]) {
                    errors[fieldAsType] = 'Required';
                }
            }
        }
        return errors;
    };

    const onSubmit = async (values: IFormRenderProps, form: any) => {
        if (values.action === 'another') return;
        if (values.action === 'delete') {
            setShowConfirmationDialog(true);
            return;
        }
        delete values['rerender'];
        delete values['action'];
        let result;
        if (mode === 'line') {
            result = await saveLinePoints(values);
        } else if (mode === 'point' && placemarkData) {
            const { placemark, saved } = placemarkData;
            const coordinates = getPlacemarkCoordinates(placemark);
            const id = getPlacemarkProperty(placemark, 'id');
            if (saved) {
                const data = getDataToSend(currentLamp as ILamp, {
                    ...values,
                    coordinates,
                });
                result = await updateLamp([
                    {
                        id,
                        fields: data,
                    },
                ]);
            } else {
                const { address, model, name, placement, power, supply_station, height, surface } =
                    values;
                let powerCopy = power;
                let nameCopy = name;
                if (!powerCopy) {
                    powerCopy = 0;
                    values.power = 0;
                }
                if (!nameCopy) {
                    const selectedValueTemp = lampModels.find((item) => item.id === model);
                    if (selectedValueTemp) {
                        nameCopy = selectedValueTemp.name;
                        values.name = nameCopy;
                    }
                }
                result = await addLamp({
                    address,
                    model,
                    name: nameCopy,
                    placement,
                    power: powerCopy,
                    supply_station,
                    id,
                    coordinates,
                    height,
                    surface,
                });
            }
        } else if (mode === 'select') {
            result = await modifySelectedPoints(values);
        }
        if (!result.payload) {
            form.reset();
        }
    };

    const handleDeleteLamp = () => {
        if (placemarkData) {
            const { placemark } = placemarkData;
            const id = getPlacemarkProperty(placemark, 'id');
            deleteLamp(id as string, placemark);
        } else if (selectLampsCount) {
            deleteLampsInsideArea();
            setShowConfirmationDialog(false);
        }
    };

    const render = ({
        handleSubmit,
        initialValues,
        form,
        dirty,
    }: FormRenderProps<IFormRenderProps>) => {
        submit = handleSubmit;
        setDraggableEndCallback((address) => {
            form.change('rerender', {});
            form.change('address', address);
        });
        return (
            <form onSubmit={handleSubmit} className={`${styles.form} container`}>
                <Field name='rerender'>{() => null}</Field>
                {showConfirmationDialog && (
                    <PointDeleteConfirmationDialog
                        handleCancel={() => {
                            form.change('action', 'another');
                            setShowConfirmationDialog(false);
                        }}
                        handleDelete={() => {
                            form.change('action', 'another');
                            handleDeleteLamp();
                        }}
                    />
                )}
                {mode === 'select' && (
                    <div className='row mb-1'>
                        <label className='col-sm-6 col-form-label col-form-label-sm'>
                            Количество ламп: <b>{selectLampsCount}</b>
                        </label>
                        <label className='col-sm-6 col-form-label col-form-label-sm'>
                            Количество питающих станций: <b>{selectSupplyStationsCount}</b>
                        </label>
                    </div>
                )}
                {mode === 'line' && linePointsIsEmpty && <LineCountMenuConnected />}
                {((mode === 'select' && allowWrite) || mode === 'point' || mode === 'line') && (
                    <>
                        <div className='row mb-1'>
                            <label
                                htmlFor='nameId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Название
                            </label>
                            <div className='col-sm-9'>
                                <Input
                                    name='name'
                                    className='form-control form-control-sm'
                                    placeholder='Имя'
                                    id='nameId'
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        {placemarkData && !placemarkData.saved && mode === 'point' && (
                            <div className='row mb-1'>
                                <label
                                    htmlFor='typeId'
                                    className='col-sm-3 col-form-label col-form-label-sm'>
                                    Тип точки
                                </label>
                                <div className='col-sm-9'>
                                    <select
                                        disabled={!allowWrite}
                                        className='form-select'
                                        defaultValue={type}
                                        onChange={() => handleTypeChange('Supply station')}>
                                        <option value={'Lamp'}>Светильник</option>
                                        <option value={'Supply station'}>Питающая станция</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className='row mb-1'>
                            <label
                                htmlFor='modelId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Модель
                            </label>
                            <div className='col-sm-9'>
                                <LampModelsSelectConnected
                                    disabled={!allowWrite}
                                    name='model'
                                    valueId={initialValues.model || ''}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <label
                                htmlFor='powerId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Мощность
                            </label>
                            <div className='col-sm-9'>
                                <Input
                                    name='power'
                                    placeholder='Мощность'
                                    className='form-control form-control-sm'
                                    id='powerId'
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <label
                                htmlFor='placementId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Размещение
                            </label>
                            <div className='col-sm-9'>
                                <Select
                                    list={getLampPlacements()}
                                    value={initialValues.placement}
                                    name='placement'
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <label
                                htmlFor='surfaceId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Тип поверхности
                            </label>
                            <div className='col-sm-9'>
                                <Select
                                    list={getLampSurfaces()}
                                    value={initialValues.surface}
                                    name='surface'
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <label
                                htmlFor='heightId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Высота установки
                            </label>
                            <div className='col-sm-9'>
                                <Input
                                    name='height'
                                    placeholder='Высота установки'
                                    className='form-control form-control-sm'
                                    id='heightId'
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        <div className='row mb-1'>
                            <label
                                htmlFor='supplyId'
                                className='col-sm-3 col-form-label col-form-label-sm'>
                                Питающая станция
                            </label>
                            <div className='col-sm-9'>
                                <SupplyStationsSelect
                                    name='supply_station'
                                    supplyStations={supplyStations}
                                    valueId={initialValues.supply_station || ''}
                                    disabled={!allowWrite}
                                />
                            </div>
                        </div>
                        {mode === 'point' && (
                            <div className='row mb-1'>
                                <label
                                    htmlFor='addressId'
                                    className='col-sm-3 col-form-label col-form-label-sm'>
                                    Адрес
                                </label>
                                <div className='col-sm-9'>
                                    <Input
                                        name='address'
                                        placeholder='Адрес'
                                        className='form-control form-control-sm'
                                        id='addressId'
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {allowWrite && (
                    <div className={`${styles.buttons} mt-3`}>
                        {((dirty && mode !== 'line') ||
                            (mode === 'point' && placemarkData && !placemarkData.saved) ||
                            (mode === 'line' && !linePointsIsEmpty)) && (
                            <button
                                type='submit'
                                className='btn btn-success btn-sm'
                                onClick={() => form.change('action', 'submit')}>
                                Сохранить
                            </button>
                        )}
                        {((placemarkData && placemarkData.saved) || selectLampsCount !== 0) && (
                            <button
                                onClick={() => form.change('action', 'delete')}
                                type='submit'
                                className='btn btn-danger btn-sm'>
                                Удалить
                            </button>
                        )}
                        {mode === 'point' && (
                            <button
                                onClick={() => {
                                    form.change('action', 'another');
                                    resetCurrentPlacemark();
                                }}
                                className='btn btn-secondary btn-sm'>
                                Отмена
                            </button>
                        )}
                        {mode === 'line' && linePointsIsEmpty && lineIsSet && (
                            <button
                                onClick={() => {
                                    form.change('action', 'another');
                                    setPointByPolyline();
                                }}
                                type='submit'
                                className='btn btn-primary btn-sm'>
                                Установить точки
                            </button>
                        )}
                    </div>
                )}
            </form>
        );
    };

    if (!lineIsSet && mode === 'line') {
        null;
    }
    return (
        <Form
            onSubmit={onSubmit}
            render={render}
            initialValues={getInitialFieldsNew(mode, placemarkData, currentLamp, prevValues)}
            validate={validate}
        />
    );
};
