import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ILampModel } from 'types/ILampModel';
import styles from './LampModelsPage.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const LampModelsPage: React.FC<IStateProps & IDispatchProps> = ({ lampModels, load }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (lampModels.length === 0 && !loading) {
            setLoading(true);
            load();
            setLoading(false);
        }
    }, []);

    const renderItem = (item: ILampModel, idx: number) => (
        <tr key={item.id}>
            <th scope='row'>{idx + 1}</th>
            <td className='col-3'>{item.id}</td>
            <td className='col-6'>{item.name}</td>
            <td style={{ backgroundColor: `${item.color}` }}></td>
            <td>
                <Link to={`/model/${item.id}`}>Редактировать</Link>
            </td>
        </tr>
    );

    const wrapperStyle = `container-xl ${styles.wrapper}`;

    return (
        <div className={wrapperStyle}>
            <Link to='/menu'>Назад</Link>
            <Link to='/model/new'>Добавить новый</Link>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>#</th>
                        <th scope='col-3'>Id</th>
                        <th scope='col-6'>Наименование</th>
                        <th scope='col'>Цвет</th>
                        <th scope='col'>Действие</th>
                    </tr>
                </thead>
                <tbody>
                    {lampModels.map((item, idx) => {
                        return renderItem(item, idx);
                    })}
                </tbody>
            </table>
        </div>
    );
};
