import { connect } from 'react-redux';
import { getCurrentLampSelector } from 'selectors/getCurrentLampSelector';
import { getSelectedLampsCountSelector } from 'selectors/getSelectedLampsCountSelector';
import { getSelectedSupplyStationsCountSelector } from 'selectors/getSelectedSupplyStationsCountSelector';
import { addLampThunkAction } from 'thunks/lamps/addLampThunkAction';
import { deleteLampsInsideAreaThunkAction } from 'thunks/lamps/deleteLampsInsideAreaThunkAction';
import { deleteLampThunkAction } from 'thunks/lamps/deleteLampThunkAction';
import { modifyLampThunkAction } from 'thunks/lamps/modifyLampThunkAction';
import { saveLinePointsThunkAction } from 'thunks/line/saveLinePointsThunkAction';
import { setPointsFromPolylineThunkAction } from 'thunks/line/setPointsFromPolylineThunkAction';
import { resetCurrentPlacemarkThunkAction } from 'thunks/map/resetCurrentPlacemarkThunkAction';
import { modifySelectedLampsThunkAction } from 'thunks/select/modifySelectedLampsThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LampMenu } from './LampMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentLamp: getCurrentLampSelector(state),
    supplyStations: state.supplyStationsReducer.supplyStations,
    mode: state.mapReducer.mapMode,
    lineIsSet: state.lineReducer.line !== null,
    linePointsIsEmpty: state.lineReducer.points.length === 0,
    lampModels: state.lampModelsReducer.lampModelsToShow,
    selectLampsCount: getSelectedLampsCountSelector(state),
    selectSupplyStationsCount: getSelectedSupplyStationsCountSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    addLamp: (data) => dispatch(addLampThunkAction(data)),
    updateLamp: (data) => dispatch(modifyLampThunkAction(data)),
    resetCurrentPlacemark: () => dispatch(resetCurrentPlacemarkThunkAction()),
    deleteLamp: (id, placemark) => dispatch(deleteLampThunkAction({ id, placemark })),
    deleteLampsInsideArea: () => dispatch(deleteLampsInsideAreaThunkAction()),
    saveLinePoints: (data) => dispatch(saveLinePointsThunkAction(data)),
    setPointByPolyline: () => dispatch(setPointsFromPolylineThunkAction()),
    modifySelectedPoints: (fields) => dispatch(modifySelectedLampsThunkAction(fields)),
});

export const LampMenuConnected = connect(mapStateToProps, mapDispatchToProps)(LampMenu);
