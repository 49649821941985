import { ILampModel } from 'types/ILampModel';
import { TAppState } from 'types/TAppState';

export const getCurrentLampModelSelector = (state: TAppState): ILampModel | null => {
    const path = location.pathname;
    const pathSplitted = path.split('/');
    const item = state.lampModelsReducer.lampModels.find(
        (item) => item.id === pathSplitted[pathSplitted.length - 1],
    );
    if (!item) return null;
    return item;
};
