import { createAsyncThunk } from '@reduxjs/toolkit';
import { addLampAction } from 'actions/lamps/addLampAction';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { getNetworkService } from 'api/getNetworkService';
import { saveLampsApiCall } from 'api/lamps/saveLampsApiCall';
import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { ILamp } from 'types/ILamp';
import { TAppState } from 'types/TAppState';
import { setPlacemarkColor } from 'utils/placemark/setPlacemarkColor';
import { setPlacemarkIcon } from 'utils/placemark/setPlacemarkIcon';

export const addLampThunkAction = createAsyncThunk<Promise<undefined | string>, ILamp>(
    'addLamp',
    async (lamp, { dispatch, getState }) => {
        const {
            mapReducer: { currentPlacemarkData },
        } = getState() as TAppState;
        const response = await saveLampsApiCall(getNetworkService(), [lamp]);
        if (!response.success) {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
            return response.statusText;
        }
        dispatch(addLampAction(lamp));
        dispatch(
            showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }),
        );
        if (currentPlacemarkData?.placemark) {
            setPlacemarkColor(currentPlacemarkData?.placemark, EPlacemarkColors.SELECTED);
            setPlacemarkIcon(currentPlacemarkData?.placemark, EPlacemarkTypes.LAMP);
            dispatch(
                setCurrentPlacemarkAction({
                    placemark: currentPlacemarkData?.placemark,
                    saved: true,
                }),
            );
        }
    },
);
