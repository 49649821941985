export enum EMapActions {
    SET_MAP = 'Map/setMap',
    SET_OBJECT_MANAGER = 'Map/setObjectManager',
    SET_MAP_CENTER = 'Map/setMapCenter',
    SET_CURRENT_PLACEMARK = 'Map/setCurrentPlacemark',
    SET_MAP_MODE = 'Map/setMapMode',
    SET_LINE_POINTS = 'Map/setLinePoints',
    RESET_STORE = 'Map/resetStore',
    SET_FILTER = 'Map/setFilter',
}
