import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserSettingsAction } from 'actions/userSettings/setUserSettingsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getUserSettingsApiCall } from 'api/userSettings/getUserSettingsApiCall';

export const getUserSettingsThunkAction = createAsyncThunk(
    'getUserSettings',
    async (_: void, { dispatch }) => {
        const response = await getUserSettingsApiCall(getNetworkService());
        if (response.success) {
            const settings = response.data;
            dispatch(setUserSettingsAction(settings));
        }
    },
);
