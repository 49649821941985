import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLineAction } from 'actions/line/setLineAction';
import { setPointsAction } from 'actions/line/setPointsAction';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { IPlacemark } from 'types/IPlacemark';
import { TAppState } from 'types/TAppState';
import { getPointsByCount } from 'utils/line/getPointsByCount';
import { getNewPlacemark } from 'utils/placemark/getNewPlacemark';
import { setPlacemarkAddress } from 'utils/placemark/setPlacemarkAddress';

export const setPointsFromPolylineThunkAction = createAsyncThunk(
    'setPointsFromPolyline',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map },
            lineReducer: { line, count },
        } = getState() as TAppState;
        if (line) {
            const placemarkArray: IPlacemark[] = [];
            const vertices = line.geometry.getCoordinates() as [number, number][];
            const verticesCount = vertices.length;
            if (verticesCount > count) {
                return;
            }
            for (const point of vertices) {
                const newPlacemark = getNewPlacemark({
                    coordinates: point,
                    preset: EPlacemarkTypes.UNSAVED,
                });
                await setPlacemarkAddress(newPlacemark);
                placemarkArray.push(newPlacemark);
                map.geoObjects.add(newPlacemark);
            }

            const countOnEachLine = Math.floor((count - verticesCount) / (verticesCount - 1));
            let over = (count - verticesCount) % (verticesCount - 1);

            for (let i = 0; i < verticesCount - 1; i++) {
                const top = vertices[i];
                const bottom = vertices[i + 1];
                let localCount = countOnEachLine;
                if (over !== 0) {
                    localCount += 1;
                    over--;
                }
                const points = getPointsByCount([top, bottom], localCount);
                for (const point of points) {
                    const newPlacemark = getNewPlacemark({
                        coordinates: point,
                        preset: EPlacemarkTypes.UNSAVED,
                    });
                    await setPlacemarkAddress(newPlacemark);
                    map.geoObjects.add(newPlacemark);
                    placemarkArray.push(newPlacemark);
                }
            }
            dispatch(setPointsAction(placemarkArray));
            map.geoObjects.remove(line);
            dispatch(setLineAction(null));
        }
    },
);
