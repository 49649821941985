import styles from './LampModelsFilter.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const LampModelsFilter: React.FC<IStateProps & IDispatchProps> = ({
    lampModels,
    filter,
    setFilter,
}) => {
    const lampFilter = filter['Lamp'];
    const onChange = (value: string, checked: boolean) => {
        if (lampFilter) {
            let array = [...(lampFilter['model'] as string[])];
            if (checked) {
                array.push(value);
            } else {
                array = array.filter((id) => id !== value);
            }
            if (array.length === 0) {
                setFilter(undefined);
            } else {
                setFilter({ type: 'Lamp', field: { model: array }, replace: true });
            }
        } else {
            setFilter({ type: 'Lamp', field: { model: [value] }, replace: true });
        }
    };
    const filterValues = lampFilter?.model as undefined | string[];

    return (
        <div className={styles.wrapper}>
            <div className='dropdown mt-3'>
                <button
                    className='btn btn-secondary dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'>
                    Фильтр моделей
                </button>
                <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                    {lampModels.map((model) => {
                        const checked = filterValues
                            ? filterValues.find((value) => value === model.id) !== undefined
                            : false;
                        return (
                            <div className='form-check dropdown-item' key={model.id}>
                                <input
                                    type='checkbox'
                                    value=''
                                    id='flexCheckChecked'
                                    checked={checked}
                                    readOnly={true}
                                />
                                <label
                                    htmlFor='flexCheckChecked'
                                    onClick={() => onChange(model.id, !checked)}>
                                    {model.name}
                                </label>
                            </div>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
