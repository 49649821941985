import { connect } from 'react-redux';
import { getCurrentSupplyStationSelector } from 'selectors/getCurrentSupplyStationSelector';
import { resetCurrentPlacemarkThunkAction } from 'thunks/map/resetCurrentPlacemarkThunkAction';
import { addSupplyStationThunkAction } from 'thunks/supplyStations/addSupplyStationThunkAction';
import { deleteSupplyStationThunkAction } from 'thunks/supplyStations/deleteSupplyStationThunkAction';
import { modifySupplyStationThunkAction } from 'thunks/supplyStations/modifySupplyStationThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { SupplyStationMenu } from './SupplyStationMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentSupplyStation: getCurrentSupplyStationSelector(state),
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    addSupplyStation: (data) => dispatch(addSupplyStationThunkAction(data)),
    updateSupplyStation: (data) => dispatch(modifySupplyStationThunkAction(data)),
    resetCurrentPlacemark: () => dispatch(resetCurrentPlacemarkThunkAction()),
    deleteSupplyStation: (id, placemark) =>
        dispatch(deleteSupplyStationThunkAction({ id, placemark })),
});

export const SupplyStationMenuConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SupplyStationMenu);
