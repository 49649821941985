import { createAsyncThunk } from '@reduxjs/toolkit';
import { setMapModeAction } from 'actions/map/setMapModeAction';
import { loadAreasThunkAction } from 'thunks/areas/loadAreasThunkAction';
import { resetAreasStoreThunkAction } from 'thunks/areas/resetAreasStoreThunkAction';
import { resetLineStoreThunkAction } from 'thunks/line/resetLineStoreThunkAction';
import { resetSelectStoreThunkAction } from 'thunks/select/resetSelectStoreThunkAction';
import { setSelectModeThunkAction } from 'thunks/select/setSelectModeThunkAction';
import { TMapMode } from 'types/TMapMode';
import { resetCurrentPlacemarkThunkAction } from './resetCurrentPlacemarkThunkAction';

export const mapModeChangeThunkAction = createAsyncThunk(
    'mapModeChange',
    async (mode: TMapMode, { dispatch }) => {
        dispatch(setMapModeAction(mode));
        await dispatch(resetCurrentPlacemarkThunkAction());
        await dispatch(resetLineStoreThunkAction());
        await dispatch(resetSelectStoreThunkAction());
        await dispatch(resetAreasStoreThunkAction());
        if (mode === 'select') {
            dispatch(setSelectModeThunkAction());
        } else if (mode === 'areas') {
            await dispatch(loadAreasThunkAction());
            dispatch(setSelectModeThunkAction());
        }
    },
);
