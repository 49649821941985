import React from 'react';
import styles from './PointDeleteConfirmationDialog.module.scss';
import { IOwnProps } from './types';

export const PointDeleteConfirmationDialog: React.FC<IOwnProps> = ({
    handleCancel,
    handleDelete,
}) => {
    return (
        <div className={styles.dialog}>
            <div className={styles.buttons}>
                <button onClick={handleDelete} className='btn btn-danger btn-sm mr-xl-3'>
                    Удалить
                </button>
                <button onClick={handleCancel} className='btn btn-secondary btn-sm'>
                    Отмена
                </button>
            </div>
        </div>
    );
};
