import { createReducer } from '@reduxjs/toolkit';
import { addLampAction } from 'actions/lamps/addLampAction';
import { deleteLampAction } from 'actions/lamps/deleteLampAction';
import { modifyLampsAction } from 'actions/lamps/modifyLampsAction';
import { setLampsAction } from 'actions/lamps/setLampsAction';
import { ILamp } from 'types/ILamp';

interface ILampsReducerInitialState {
    lamps: { [id: string]: ILamp };
}

const lampsReducerInitialState: ILampsReducerInitialState = {
    lamps: {},
};

export const lampsReducer = createReducer(lampsReducerInitialState, (builder) =>
    builder
        .addCase(setLampsAction, (state, { payload: lamps }) => {
            for (const lamp of lamps) {
                state.lamps[lamp.id] = lamp;
            }
        })
        .addCase(addLampAction, (state, { payload: newLamp }) => {
            state.lamps[newLamp.id] = newLamp;
        })
        .addCase(modifyLampsAction, (state, { payload: data }) => {
            for (const item of data) {
                state.lamps[item.id] = { ...state.lamps[item.id], ...item.fields };
            }
        })
        .addCase(deleteLampAction, (state, { payload: id }) => {
            delete state.lamps[id];
        }),
);
