import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentAreaAction } from 'actions/areas/setCurrentAreaAction';
import { getCurrentAreaSelector } from 'selectors/getCurrentAreaSelector';
import { TAppState } from 'types/TAppState';

export const resetCurrentAreaThunkAction = createAsyncThunk(
    'resetCurrentArea',
    (_: void, { dispatch, getState }) => {
        const {
            areasReducer: { currentArea, geoCollection },
        } = getState() as TAppState;
        if (currentArea) {
            const saved = getCurrentAreaSelector(getState() as TAppState) !== null;
            if (!saved) {
                geoCollection.remove(currentArea);
            }
        }
        dispatch(setCurrentAreaAction(null));
    },
);
