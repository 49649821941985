import { connect } from 'react-redux';
import { TAppState } from 'types/TAppState';
import { Notification } from './Notification';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    notificationToShow: state.appReducer.notificationToShow,
});

export const NotificationConnected = connect(mapStateToProps)(Notification);
