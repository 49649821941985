import { getLampModels } from './lampProperties/getLampModels';
const colors = [
    '#808080',
    '#C0C0C0',
    '#FF00FF',
    '#800080',
    '#FF0000',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#008000',
    '#00FFFF',
    '#008080',
    '#0000FF',
    '#000080',
];
const lampsColors: { [id: string]: string } = {};

const setLampsColors = () => {
    const lampsModels = getLampModels();
    lampsModels.forEach((lamp, idx) => {
        lampsColors[lamp] = colors[idx];
    });
};

export { lampsColors, setLampsColors };
