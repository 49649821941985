import { INetworkService } from 'types/api/INetworkService';
import { IArea } from 'types/IArea';

interface IPayload {
    id: string;
    fields: Partial<IArea>;
}

export const modifyAreaApiCall = (networkService: INetworkService, { fields, id }: IPayload) =>
    networkService.put({ url: `/api/areas/${id}`, data: fields });
