import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetLineReducerAction } from 'actions/line/resetLineReducerAction';
import { resetMapReducerAction } from 'actions/map/resetMapReducerAction';
import { resetSelectReducerAction } from 'actions/select/resetSelectReducerAction';
import { setUserMapSettingsThunkAction } from 'thunks/userSettings/setUserMapSettingsThunkAction';
import { TAppState } from 'types/TAppState';

export const destroyMapThunkAction = createAsyncThunk(
    'destroyMap',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map: mapInstance },
        } = getState() as TAppState;
        if (mapInstance) {
            mapInstance.destroy();
            await dispatch(setUserMapSettingsThunkAction());
            dispatch(resetMapReducerAction());
            dispatch(resetLineReducerAction());
            dispatch(resetSelectReducerAction());
        }
    },
);
