const models = [
    'BRIG 140',
    'BRIG 120',
    'BRIG 75',
    'FLA 70',
    'FLA 45',
    'FLA 45 (D2)',
    'FLS 35 (4000K)',
    'FLS 45',
    'FLS 35 (5000K)',
    'Lednik 70',
    'Lednik 40',
];
export const getLampModels = () => models;
