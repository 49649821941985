import { Input } from 'components/common/Input';
import { Form, FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import styles from './LampModelPage.module.scss';
import { IDispatchProps, IFormRenderProps, IStateProps } from './types';
import { getEmptyLampModel } from './utils';

export const LampModelPage: React.FC<IStateProps & IDispatchProps> = ({
    currentLampModel,
    areas,
    modify,
    save,
    del,
}) => {
    const navigate = useNavigate();

    const onValidate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        for (const value in values) {
            if (!value) {
                errors[value] = 'Error';
            }
        }
        return errors;
    };

    const onSubmit = async (values: IFormRenderProps) => {
        if (values.action === 'submit') {
            delete values['action'];
            let error;
            if (currentLampModel) {
                error = await modify({ id: currentLampModel.id, fields: values });
            } else {
                error = await save(values);
            }
            if (error.payload) {
                return { error: true };
            }
        } else if (values.action === 'delete') {
            const error = await del(currentLampModel?.id as string);
            if (error.payload) {
                return { error: true };
            }
        }
        navigate('/models');
    };

    const render = ({ handleSubmit, values, form }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className={`${styles.form} `}>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-2 col-form-label'>
                    Id
                </label>
                <div className='col-sm-10'>
                    <Input name='id' placeholder='Id' className='form-control' disabled={true} />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-2 col-form-label'>
                    Имя
                </label>
                <div className='col-sm-10'>
                    <Input name='name' placeholder='Имя' className='form-control' />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor='colFormLabel' className='col-sm-2 col-form-label'>
                    Цвет
                </label>
                <div className='col-sm-10'>
                    <div className='input-group mb-3'>
                        {/* <Input name='color' placeholder='Цвет' className='form-control' /> */}
                        <Input
                            type='color'
                            className='form-control form-control-color'
                            id='exampleColorInput'
                            name='color'
                        />
                    </div>
                </div>
            </div>
            {areas.length !== 0 && (
                <div className='row mb-3'>
                    <label htmlFor='colFormLabel' className='col-sm-4 col-form-label'>
                        Ограничения
                    </label>
                    <div className='col-sm-8'>
                        <ul className='list-group'>
                            {areas.map((area) => (
                                <li className='list-group-item' key={area.id}>
                                    <input
                                        className='form-check-input me-1'
                                        type='checkbox'
                                        value=''
                                        aria-label='...'
                                        checked={
                                            values.areas.find((e) => e === area.id) !== undefined
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                form.change('areas', [...values.areas, area.id]);
                                            } else {
                                                form.change(
                                                    'areas',
                                                    values.areas.filter((a) => a !== area.id),
                                                );
                                            }
                                        }}
                                    />
                                    {area.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className={styles.buttons}>
                <button
                    onClick={() => form.change('action', 'submit')}
                    type='submit'
                    className='btn btn-success'>
                    {currentLampModel ? 'Сохранить' : 'Добавить'}
                </button>
                {currentLampModel && (
                    <button
                        onClick={() => form.change('action', 'delete')}
                        type='submit'
                        className='btn btn-outline-danger'>
                        Удалить
                    </button>
                )}
                <button
                    onClick={() => {
                        form.change('action', 'another');
                    }}
                    className='btn btn-outline-secondary'>
                    Отмена
                </button>
            </div>
        </form>
    );

    const wrapperStyle = `container-xl ${styles.wrapper}`;
    return (
        <div className={wrapperStyle}>
            {currentLampModel && <h4>Редактирование {currentLampModel.name}</h4>}
            {!currentLampModel && <h4>Добавление новой модели светильника</h4>}
            <Form
                onSubmit={onSubmit}
                render={render}
                initialValues={currentLampModel || getEmptyLampModel()}
                validate={onValidate}
            />
        </div>
    );
};
