import { ISupplyStation } from 'types/ISupplyStation';
import { Select } from '../Select';
import { IOwnProps, IStateProps } from './types';

export const SupplyStationsSelect: React.FC<IOwnProps & IStateProps> = ({
    supplyStations,
    valueId,
    name,
    disabled,
}) => {
    const selectedValueTemp = supplyStations[valueId];
    let selectedValue = 'blanc';
    if (selectedValueTemp) selectedValue = selectedValueTemp.name;
    const supplyStationArray: ISupplyStation[] = [];
    for (const id in supplyStations) {
        supplyStationArray.push(supplyStations[id]);
    }
    return (
        <Select
            name={name}
            value={selectedValue}
            list={supplyStationArray.map((item) => item.name)}
            format={(value: string) =>
                supplyStationArray.find((item) => item.id === value)?.name as string
            }
            parse={(value: string) =>
                supplyStationArray.find((item) => item.name === value)?.id as string
            }
            disabled={disabled}
        />
    );
};
