import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLineAction } from 'actions/line/setLineAction';
import { setLinePointsAction } from 'actions/line/setLinePointsAction';
import { setPointsAction } from 'actions/line/setPointsAction';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { getCurrentLampSelector } from 'selectors/getCurrentLampSelector';
import { getCurrentSupplyStationSelector } from 'selectors/getCurrentSupplyStationSelector';
import { setLampModelsToShowThunkAction } from 'thunks/lampModels/setLampModelsToShowThunkAction';
import { placemarkDragEndEventThunkAction } from 'thunks/placemark/placemarkDragEndEventThunkAction';
import { TAppState } from 'types/TAppState';
import { getFeature } from 'utils/getFeature';
import { getNewPlacemark } from 'utils/placemark/getNewPlacemark';
import { getPlacemarkCoordinates } from 'utils/placemark/getPlacemarkCoordinates';
import { setPlacemarkAddress } from 'utils/placemark/setPlacemarkAddress';

export const addMapClickEventThunkAction = createAsyncThunk<void, any>(
    'addMapClickEvent',
    async (map, { dispatch, getState }) => {
        const {
            accountReducer: { user },
        } = getState() as TAppState;
        if (user?.role !== 'r') {
            map.events.add('click', async (e: any) => {
                const {
                    mapReducer: { currentPlacemarkData, mapMode, objectManager },
                    lineReducer: { linePoints, points, line },
                    lampModelsReducer: { lampModels },
                    accountReducer: { user },
                } = getState() as TAppState;
                if (mapMode === 'point') {
                    if (currentPlacemarkData) {
                        if (currentPlacemarkData.saved) {
                            const currentLamp = getCurrentLampSelector(getState() as TAppState);
                            const currentSupplyStations = getCurrentSupplyStationSelector(
                                getState() as TAppState,
                            );
                            const feature = getFeature(
                                currentLamp,
                                currentSupplyStations,
                                lampModels,
                            );
                            if (feature) {
                                objectManager.add(feature);
                            }
                        }
                        map.geoObjects.remove(currentPlacemarkData.placemark);
                    }
                    const clickCoordinates = e.get('coords');
                    const newPlacemark = getNewPlacemark({
                        coordinates: clickCoordinates,
                        preset: EPlacemarkTypes.UNSAVED,
                        draggable: true,
                    });
                    await setPlacemarkAddress(newPlacemark);
                    if (user?.role !== 'r') {
                        newPlacemark.events.add('dragend', async (e: any) =>
                            dispatch(placemarkDragEndEventThunkAction(e)),
                        );
                    }
                    dispatch(setCurrentPlacemarkAction({ placemark: newPlacemark, saved: false }));
                    map.geoObjects.add(newPlacemark);
                    await dispatch(setLampModelsToShowThunkAction(clickCoordinates));
                } else if (mapMode === 'line') {
                    const [top, bottom] = linePoints;
                    const clickCoordinates = e.get('coords');
                    if (!top) {
                        const newPlacemark = getNewPlacemark({
                            coordinates: clickCoordinates,
                            preset: EPlacemarkTypes.UNSAVED,
                        });
                        points.forEach((placemark) => {
                            map.geoObjects.remove(placemark);
                        });
                        if (line) {
                            map.geoObjects.remove(line);
                        }
                        map.geoObjects.add(newPlacemark);
                        dispatch(setPointsAction([]));
                        dispatch(setLinePointsAction([newPlacemark, null]));
                    } else if (!bottom) {
                        const polyline = new ymaps.Polyline(
                            [getPlacemarkCoordinates(top), clickCoordinates],
                            {},
                            {
                                strokeColor: '#00000088',
                                strokeWidth: 4,
                                editorMaxPoints: 15,
                            },
                        );
                        map.geoObjects.remove(top);
                        map.geoObjects.add(polyline);
                        polyline.editor.startEditing();
                        dispatch(setLineAction(polyline));
                        dispatch(setLinePointsAction([null, null]));
                    }
                }
            });
        }
    },
);
