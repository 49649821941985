import { ISupplyStation } from 'types/ISupplyStation';
import { TAppState } from 'types/TAppState';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const getCurrentSupplyStationSelector = (state: TAppState): ISupplyStation | null => {
    const placemarkData = state.mapReducer.currentPlacemarkData;
    if (!placemarkData) {
        return null;
    }
    const id = getPlacemarkProperty(placemarkData.placemark, 'id') as string;
    return state.supplyStationsReducer.supplyStations[id] || null;
};
