import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAreasAction } from 'actions/areas/setAreasAction';
import { setCurrentAreaAction } from 'actions/areas/setCurrentAreaAction';
import { setGeoCollectionAction } from 'actions/areas/setGeoCollectionAction';
import { getAreasApiCall } from 'api/areas/getAreasApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { IArea } from 'types/IArea';
import { TAppState } from 'types/TAppState';

export const loadAreasThunkAction = createAsyncThunk(
    'loadAreas',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map },
        } = getState() as TAppState;
        const result = await getAreasApiCall(getNetworkService());
        if (result.success) {
            const geoCollection = new ymaps.GeoObjectCollection(null, {});
            const areas = result.data as IArea[];
            for (const area of areas) {
                const style = {
                    strokeColor: `${area.color}`,
                    strokeOpacity: 0.7,
                    strokeWidth: 3,
                    fillColor: `${area.color}`,
                    fillOpacity: 0.4,
                };
                const newArea = new ymaps.Polygon([area.coordinates], { id: area.id }, style);
                newArea.events.add('click', () => {
                    dispatch(setCurrentAreaAction(newArea));
                });
                geoCollection.add(newArea);
            }
            dispatch(setAreasAction(areas));
            dispatch(setGeoCollectionAction(geoCollection));
            map.geoObjects.add(geoCollection);
        }
    },
);
