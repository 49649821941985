import { createAction } from '@reduxjs/toolkit';
import { ESupplyStationsActions } from 'constants/actions/ESupplyStationsActions';
import { ISupplyStation } from 'types/ISupplyStation';

interface IPayload {
    id: string;
    fields: Partial<ISupplyStation>;
}

export const modifySupplyStationAction = createAction<IPayload>(
    ESupplyStationsActions.MODIFY_SUPPLY_STATION,
);
