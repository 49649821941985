import { INetworkService } from 'types/api/INetworkService';
import { IUser } from 'types/IUser';

interface IPayload {
    id: string;
    fields: Partial<Omit<IUser, 'id'>>;
}

export const modifyUserApiCall = (networkService: INetworkService, { fields, id }: IPayload) =>
    networkService.put({ url: `/api/users/${id}`, data: fields });
