import { IDispatchProps, IStateProps } from './types';

export const LineCountMenu: React.FC<IStateProps & IDispatchProps> = ({ count, setCount }) => (
    <div className='row mb-1'>
        <label htmlFor='count' className='col-sm-3 col-form-label col-form-label-sm'>
            Количество ламп
        </label>
        <div className='col-sm-9'>
            <input
                id='count'
                type='number'
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className='form-control form-control-sm'
            />
        </div>
    </div>
    // <div className='menu'>
    //     <label htmlFor='count'>
    //         Количество точек
    //     </label>
    //     <input id='count' type='number' value={count} onChange={(e) => setCount(parseInt(e.target.value))} />
    // </div>
);
