import { createReducer } from '@reduxjs/toolkit';
import { addSupplyStationAction } from 'actions/supplyStations/addSupplyStationAction';
import { deleteSupplyStationAction } from 'actions/supplyStations/deleteSupplyStationAction';
import { modifySupplyStationAction } from 'actions/supplyStations/modifySupplyStationAction';
import { setSupplyStationsAction } from 'actions/supplyStations/setSupplyStationsAction';
import { ISupplyStation } from 'types/ISupplyStation';

interface ISupplyStationReducerInitialState {
    supplyStations: { [id: string]: ISupplyStation };
}

const supplyStationsReducerInitialState: ISupplyStationReducerInitialState = {
    supplyStations: {},
};

export const supplyStationsReducer = createReducer(supplyStationsReducerInitialState, (builder) =>
    builder
        .addCase(setSupplyStationsAction, (state, { payload: supplyStations }) => {
            for (const supplyStation of supplyStations) {
                state.supplyStations[supplyStation.id] = supplyStation;
            }
        })
        .addCase(addSupplyStationAction, (state, { payload: newSupplyStation }) => {
            state.supplyStations[newSupplyStation.id] = newSupplyStation;
        })
        .addCase(modifySupplyStationAction, (state, { payload: { id, fields } }) => {
            state.supplyStations[id] = { ...state.supplyStations[id], ...fields };
        })
        .addCase(deleteSupplyStationAction, (state, { payload: id }) => {
            delete state.supplyStations[id];
        }),
);
