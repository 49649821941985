import { setLineCountAction } from 'actions/line/setLineCountAction';
import { connect } from 'react-redux';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { LineCountMenu } from './LineCountMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    count: state.lineReducer.count,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    setCount: (count) => dispatch(setLineCountAction(count)),
});

export const LineCountMenuConnected = connect(mapStateToProps, mapDispatchToProps)(LineCountMenu);
