import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNotificationToShowAction } from 'actions/app/setNotificationToShowAction';
import { INotification } from 'types/INotification';
import { TAppDispatch } from 'types/TAppDispatch';
import { log } from 'utils/log';

const notifications: INotification[] = [];

const showNotification = (dispatch: TAppDispatch) => {
    if (!notifications[0].timerHandle) {
        dispatch(setNotificationToShowAction({ ...notifications[0] }));
        notifications[0].timerHandle = setTimeout(() => {
            dispatch(setNotificationToShowAction(null));
            const [shownNotification] = notifications.splice(0, 1);
            if (notifications.length > 0) {
                showNotification(dispatch);
            }
            if (shownNotification.closeAction) {
                shownNotification.closeAction();
            }
        }, notifications[0].timeout);
    }
};

export const showNotificationThunkAction = createAsyncThunk(
    'showNotification',
    async (notification: Omit<INotification, 'timerHandle'>, { dispatch }) => {
        if (notifications.length > 0) {
            if (notifications[notifications.length - 1].text === notification.text) {
                log.debug('Same error message. Skipped.');
                return;
            }
        }
        notifications.push(notification);
        showNotification(dispatch);
    },
);
