import { IPlacemarkData } from 'types/IPlacemarkData';
import { ISupplyStation } from 'types/ISupplyStation';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';
import { IFormRenderProps } from './types';

export const getInitialFields = (
    placemarkData: IPlacemarkData,
    currentSupplyStation: ISupplyStation | null,
): Partial<IFormRenderProps> => {
    if (placemarkData) {
        let address = getPlacemarkProperty(placemarkData.placemark, 'address');
        if (placemarkData.saved && currentSupplyStation) {
            address ||= currentSupplyStation.address;
        }
        return { ...currentSupplyStation, address };
    } else {
        return {};
    }
};

export const getDataToSend = (currentSupplyStation: ISupplyStation, values: IFormRenderProps) => {
    const fields: { [id: string]: any } = {};
    for (const key in values) {
        const keyType = key as keyof ISupplyStation;
        const keyType2 = key as keyof IFormRenderProps;
        if (
            currentSupplyStation[keyType] !== values[keyType2] &&
            keyType2 !== 'id' &&
            keyType2 !== 'rerender'
        ) {
            fields[keyType2] = values[keyType2];
        }
    }
    return fields;
};

export const getSupplyStationFields = () => ['name', 'cabinet', 'organization', 'address'];
