import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAppState } from 'types/TAppState';
import { getDraggableEndCallback } from 'utils/draggableEndCallback';
import { setPlacemarkAddress } from 'utils/placemark/setPlacemarkAddress';

export const placemarkDragEndEventThunkAction = createAsyncThunk(
    'placemarkDragEndEvent',
    async (e: any, { getState }) => {
        const targetPlacemark = e.get('target');
        const {
            mapReducer: { currentPlacemarkData },
        } = getState() as TAppState;

        if (currentPlacemarkData && targetPlacemark == currentPlacemarkData.placemark) {
            const address = await setPlacemarkAddress(targetPlacemark);
            const cb = getDraggableEndCallback();
            if (cb) cb(address);
        }
    },
);
