import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUsersAction } from 'actions/users/setUsersAction';
import { getNetworkService } from 'api/getNetworkService';
import { deleteUserApiCall } from 'api/users/deleteUserApiCall';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';

export const deleteUserThunkAction = createAsyncThunk<undefined | string, string>(
    'deleteUser',
    async (id, { getState, dispatch }) => {
        const {
            usersReducer: { users },
        } = getState() as TAppState;
        const response = await deleteUserApiCall(getNetworkService(), id);
        if (!response.success) {
            dispatch(showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }));
            return response.statusText;
        }
        dispatch(setUsersAction(users.filter((user) => user.id !== id)));
        dispatch(showNotificationThunkAction({ text: 'Удален', timeout: 3000, type: 'success' }));
    },
);
