import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginApiCall } from 'api/account/loginApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { getCurrentUserThunkAction } from './getCurrentUserThunkAction';

interface IPayload {
    username: string;
    password: string;
}

export const loginThunkAction = createAsyncThunk<void, IPayload>(
    'login',
    async ({ password, username }, { dispatch }) => {
        const response = await loginApiCall(getNetworkService(), { password, username });
        if (!response.success) {
            return;
        }
        await dispatch(getCurrentUserThunkAction());
    },
);
