import { connect } from 'react-redux';
import { mapModeChangeThunkAction } from 'thunks/map/mapModeChangeThunkAction';
import { setUserMapSettingsThunkAction } from 'thunks/userSettings/setUserMapSettingsThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { MapMenu } from './MapMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    mapMode: state.mapReducer.mapMode,
    models: state.lampModelsReducer.lampModels,
    objectManager: state.mapReducer.objectManager,
    user: state.accountReducer.user,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    changeMapMode: (mode) => dispatch(mapModeChangeThunkAction(mode)),
    saveMapSettings: () => dispatch(setUserMapSettingsThunkAction()),
});

export const MapMenuConnected = connect(mapStateToProps, mapDispatchToProps)(MapMenu);
