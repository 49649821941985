import { connect } from 'react-redux';
import { logoutThunkAction } from 'thunks/account/logoutThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { MenuPage } from './MenuPage';
import { IDispatchProps, IStateProps } from './types';

const mapStatToProps = (state: TAppState): IStateProps => ({
    user: state.accountReducer.user,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    logout: () => dispatch(logoutThunkAction()),
});

export const MenuPageConnected = connect(mapStatToProps, mapDispatchToProps)(MenuPage);
