import { TRole } from 'types/TRole';
import { TRoleString } from 'types/TRoleString';

export const convertStringToRole = (role: TRoleString): TRole => {
    if (role === 'Администратор') {
        return 'admin';
    } else if (role === 'Только просмотр') {
        return 'r';
    } else if (role === 'Редактирование') {
        return 'rw';
    }
    return 'r';
};
