import { Field } from 'react-final-form';
import styles from './Input.module.scss';
import { IOwnProps } from './types';

export const Input: React.FC<IOwnProps> = ({
    name,
    placeholder,
    className = '',
    id,
    disabled = false,
    ref,
    type = 'text',
}) => (
    <Field name={name}>
        {({ input, meta }) => (
            <input
                {...input}
                className={`${className} ${meta.error ? styles.error : ''}`}
                placeholder={placeholder}
                id={id}
                disabled={disabled}
                ref={ref}
                type={type}
            />
        )}
    </Field>
);
