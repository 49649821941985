import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MenuPage.module.scss';
import { IDispatchProps, IStateProps } from './types';

export const MenuPage: React.FC<IDispatchProps & IStateProps> = ({ user, logout }) => {
    const wrapperStyle = `container-xl ${styles.wrapper}`;

    return (
        <div className={wrapperStyle}>
            <div className={styles.header}>
                <h3>Главное меню</h3>
                <div className={styles.account}>
                    <div>
                        Привет,{' '}
                        <strong>
                            {user?.first_name} {user?.last_name}
                        </strong>
                        !
                    </div>
                    <button
                        className='btn btn-outline-dark me-md-2 btn-sm'
                        type='button'
                        onClick={logout}>
                        Выход
                    </button>
                </div>
            </div>
            <ul className='list-unstyled list-group'>
                {user?.role === 'admin' && (
                    <>
                        <Link className='list-group-item' to='/users'>
                            Пользователи
                        </Link>

                        <Link className='list-group-item' to='/models'>
                            Редактирование типов светильников
                        </Link>
                        <Link className='list-group-item' to='/export'>
                            Выгрузка
                        </Link>
                    </>
                )}
                <Link className='list-group-item' to='/map'>
                    Карта
                </Link>
            </ul>
        </div>
    );
};
