import { connect } from 'react-redux';
import { TAppState } from 'types/TAppState';
import { LampModelsSelect } from './LampModelsSelect';
import { IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    lampModels: state.lampModelsReducer.lampModelsToShow,
});

export const LampModelsSelectConnected = connect(mapStateToProps)(LampModelsSelect);
