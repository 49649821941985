import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLampModelsPermissionsAction } from 'actions/lampModels/setLampModelsPermissionsAction';
import { getNetworkService } from 'api/getNetworkService';
import { getLampModelsPermissionsApiCall } from 'api/lampModels/getLampModelsPermissionApiCall';

export const getLampModelsPermissionsThunkAction = createAsyncThunk(
    'getLampModelsPermissions',
    async (_: void, { dispatch }) => {
        const response = await getLampModelsPermissionsApiCall(getNetworkService());
        if (response.success) {
            const permissions = response.data as { id: string; areas: string[] }[];
            dispatch(setLampModelsPermissionsAction(permissions));
        }
    },
);
