import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { getCurrentLampSelector } from 'selectors/getCurrentLampSelector';
import { getCurrentSupplyStationSelector } from 'selectors/getCurrentSupplyStationSelector';
import { TAppState } from 'types/TAppState';
import { getFeature } from 'utils/getFeature';
import { setFilterThunkAction } from './setFilterThunkAction';

export const resetCurrentPlacemarkThunkAction = createAsyncThunk<void>(
    'resetCurrentPlacemark',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map, objectManager, currentPlacemarkData },
            lampModelsReducer: { lampModels },
        } = getState() as TAppState;
        if (currentPlacemarkData) {
            if (currentPlacemarkData.saved) {
                const currentLamp = getCurrentLampSelector(getState() as TAppState);
                const currentSupplyStations = getCurrentSupplyStationSelector(
                    getState() as TAppState,
                );
                const previousFeature = getFeature(currentLamp, currentSupplyStations, lampModels);
                if (previousFeature) {
                    objectManager.add(previousFeature);
                }
            }
            map.geoObjects.remove(currentPlacemarkData.placemark);
        }
        await dispatch(setFilterThunkAction());
        dispatch(setCurrentPlacemarkAction(null));
    },
);
