import { configureStore } from '@reduxjs/toolkit';
import { accountReducer } from 'reducers/accountReducer';
import { appReducer } from 'reducers/appReducer';
import { areasReducer } from 'reducers/areasReducer';
import { lampModelsReducer } from 'reducers/lampModelsReducer';
import { lampsReducer } from 'reducers/lampsReducer';
import { lineReducer } from 'reducers/lineReducer';
import { mapReducer } from 'reducers/mapReducer';
import { selectReducer } from 'reducers/selectReducer';
import { supplyStationsReducer } from 'reducers/supplyStationReducer';
import { settingsReducer } from 'reducers/userSettingsReducer';
import { usersReducer } from 'reducers/usersReducer';

export const appStore = configureStore({
    reducer: {
        mapReducer,
        lampsReducer,
        accountReducer,
        supplyStationsReducer,
        lineReducer,
        lampModelsReducer,
        selectReducer,
        settingsReducer,
        appReducer,
        usersReducer,
        areasReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true,
        }),
});
