import { createAction } from '@reduxjs/toolkit';
import { ELampsActions } from 'constants/actions/ELampsActions';
import { ILamp } from 'types/ILamp';

interface IPayload {
    id: string;
    fields: Partial<ILamp>;
}

export const modifyLampsAction = createAction<IPayload[]>(ELampsActions.MODIFY_LAMPS);
