import { createReducer } from '@reduxjs/toolkit';
import { modifyUserAction } from 'actions/users/modifyUserAction';
import { resetUsersReducerAction } from 'actions/users/resetUsersReducerAction';
import { setUsersAction } from 'actions/users/setUsersAction';
import { IUser } from 'types/IUser';

interface IUsersReducer {
    users: IUser[];
}

const usersReducerInitialState: IUsersReducer = {
    users: [],
};

export const usersReducer = createReducer(usersReducerInitialState, (builder) =>
    builder
        .addCase(resetUsersReducerAction, () => {
            return usersReducerInitialState;
        })
        .addCase(setUsersAction, (state, { payload: users }) => {
            state.users = users;
        })
        .addCase(modifyUserAction, (state, { payload: data }) => {
            state.users = state.users.map((user) => {
                if (user.id === data.id) {
                    return { ...user, ...data.fields };
                }
                return user;
            });
        }),
);
