import React from 'react';
import { Field } from 'react-final-form';
import styles from './Select.module.scss';
import { IOwnProps } from './types';

export const Select: React.FC<IOwnProps> = ({ list, name, disabled, format, parse }) => (
    <Field name={name} format={format} parse={parse}>
        {({ input, meta }) => (
            <select
                className={`form-select ${meta.error ? styles.error : ''}`}
                {...input}
                disabled={disabled}>
                <option value='blanc' key={'blanс'}>
                    Выберите значение
                </option>
                {list.map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </select>
        )}
    </Field>
);
