import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoginRequestStatusAction } from 'actions/account/setLoginRequestStatusAction';
import { setUserAction } from 'actions/account/setUserAction';
import { resetUsersReducerAction } from 'actions/users/resetUsersReducerAction';
import { resetUserSettingsReducerAction } from 'actions/userSettings/resetUserSettingsReducerAction';
import { logoutApiCall } from 'api/account/logoutApiCall';
import { getNetworkService } from 'api/getNetworkService';

export const logoutThunkAction = createAsyncThunk<void>('logout', async (_: void, { dispatch }) => {
    const response = await logoutApiCall(getNetworkService());
    if (!response.success) {
        dispatch(resetUserSettingsReducerAction());
        dispatch(resetUsersReducerAction());
        dispatch(setUserAction(null));
        dispatch(setLoginRequestStatusAction('initial'));
    }
});
