import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLampsAction } from 'actions/lamps/setLampsAction';
import { setPointsAction } from 'actions/line/setPointsAction';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { getNetworkService } from 'api/getNetworkService';
import { saveLampsApiCall } from 'api/lamps/saveLampsApiCall';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { batch } from 'react-redux';
import { IFeature } from 'types/feature/IFeature';
import { ILamp } from 'types/ILamp';
import { TAppState } from 'types/TAppState';
import { getLampColor } from 'utils/getLampColor';
import { getPlacemarkCoordinates } from 'utils/placemark/getPlacemarkCoordinates';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

export const saveLinePointsThunkAction = createAsyncThunk<
    undefined | string,
    Exclude<ILamp, 'address, coordinates'>
>('saveLinePoints', async (information, { getState, dispatch }) => {
    const {
        lineReducer: { points },
        lampModelsReducer: { lampModels },
        mapReducer: { map, objectManager },
    } = getState() as TAppState;

    const features: IFeature[] = [];
    const arrayToSend = points.map((placemark): ILamp => {
        const { model, placement, power, supply_station, name, height, surface } = information;
        const address = getPlacemarkProperty(placemark, 'address');
        const id = getPlacemarkProperty(placemark, 'id');
        const coordinates = getPlacemarkCoordinates(placemark);
        const color = getLampColor(information, lampModels);
        features.push({
            id,
            geometry: { type: 'Point', coordinates },
            type: 'Feature',
            options: { preset: EPlacemarkTypes.LAMP, iconColor: color },
            properties: {
                type: 'Lamp',
                supply_station,
                placement,
                model,
                power: power.toString(),
                id,
                surface,
                height: height.toString(),
            },
        });
        return {
            address,
            coordinates,
            id,
            model,
            name,
            placement,
            supply_station,
            power,
            height,
            surface,
        };
    });
    const response = await saveLampsApiCall(getNetworkService(), arrayToSend);
    if (!response.success) {
        return response.statusText;
    }
    batch(() => {
        points.forEach((placemark) => {
            map.geoObjects.remove(placemark);
        });
        dispatch(setLampsAction(arrayToSend));
        objectManager.add(features);
    });
    dispatch(setPointsAction([]));
    dispatch(setCurrentPlacemarkAction(null));
});
