import { NotificationConnected } from 'components/common/Notification';
import { LoginCheckerConnected } from 'components/LoginChecker';
import { ExportPageConnected } from 'pages/ExportPage/ExportPageConnected';
import { LampModelPageConnected } from 'pages/LampModelPage';
import { LampModelsPageConnected } from 'pages/LampModelsPage';
import { LoginPageConnected } from 'pages/LoginPage';
import { MapPage } from 'pages/MapPage';
import { MenuPageConnected } from 'pages/MenuPage';
import { UserPageConnected } from 'pages/UserPage';
import { UsersPageConnected } from 'pages/UsersPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

export const App = () => (
    <BrowserRouter>
        <LoginCheckerConnected>
            <NotificationConnected />
            <Routes>
                <Route path='/users' element={<UsersPageConnected />} />
                <Route path='/users/:id' element={<UserPageConnected />} />
                <Route path='/login' element={<LoginPageConnected />} />
                <Route path='/map' element={<MapPage />} />
                <Route path='/menu' element={<MenuPageConnected />} />
                <Route path='/models' element={<LampModelsPageConnected />} />
                <Route path='/model/new' element={<LampModelPageConnected />} />
                <Route path='/model/:id' element={<LampModelPageConnected />} />
                <Route path='/export' element={<ExportPageConnected />} />
            </Routes>
        </LoginCheckerConnected>
    </BrowserRouter>
);
