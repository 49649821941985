import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentAreaAction } from 'actions/areas/setCurrentAreaAction';
import { setGeoQueryAction } from 'actions/select/setGeoQueryAction';
import { setSelectedAreaAction } from 'actions/select/setSelectedAreaAction';
import { setSelectedLampsAction } from 'actions/select/setSelectedLampsAction';
import { setSelectedSupplyStationsAction } from 'actions/select/setSelectedSupplyStationsAction';
import { getCurrentAreaSelector } from 'selectors/getCurrentAreaSelector';
import { ILamp } from 'types/ILamp';
import { IPlacemark } from 'types/IPlacemark';
import { ISupplyStation } from 'types/ISupplyStation';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { TPlacemarkTypes } from 'types/TPlacemarkTypes';
import { getUUID } from 'utils/getUUID';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

const style = {
    strokeColor: '#ff00ff',
    strokeOpacity: 0.7,
    strokeWidth: 3,
    fillColor: '#ff00ff',
    fillOpacity: 0.4,
};

let paintProcess: any;
export let mouseDownEvent: any = null;
export let mouseUpEvent: any = null;

const mouseDownEventFn = (map: any, getState: () => unknown) => (e: any) => {
    const {
        selectReducer: { area },
        mapReducer: { mapMode },
        areasReducer: { currentArea, geoCollection },
    } = getState() as TAppState;
    if (e.get('ctrlKey')) {
        if (mapMode !== 'areas') {
            if (area) {
                map.geoObjects.remove(area);
            }
        } else {
            if (currentArea && getCurrentAreaSelector(getState() as TAppState) === null) {
                geoCollection.remove(currentArea);
            }
        }
        paintProcess = ymaps.ext.paintOnMap(map, e, { style: style });
    }
};

const mouseUpEventFn = (map: any, getState: () => unknown, dispatch: TAppDispatch) => (e: any) => {
    if (paintProcess) {
        const {
            lampsReducer: { lamps },
            supplyStationsReducer: { supplyStations },
            selectReducer: { geoQuery },
            mapReducer: { objectManager, mapMode },
            areasReducer: { geoCollection },
        } = getState() as TAppState;
        const coordinates = paintProcess.finishPaintingAt(e);
        const newArea = new ymaps.Polygon([coordinates], { id: getUUID() }, { ...style });
        if (mapMode === 'areas') {
            geoCollection.add(newArea);
            dispatch(setCurrentAreaAction(newArea));
        } else {
            dispatch(setSelectedAreaAction(newArea));
            map.geoObjects.add(newArea);
            const objectsInsideCircle = geoQuery.searchInside(newArea);
            const lampsArray: ILamp[] = [];
            const supplyStationsArray: ISupplyStation[] = [];
            objectsInsideCircle.each((item: any) => {
                const placemark: IPlacemark = item;
                const id = getPlacemarkProperty(placemark, 'id');
                if (objectManager.getObjectState(id).isFilteredOut) return;
                const type: TPlacemarkTypes = getPlacemarkProperty(placemark, 'type');
                if (type === 'Lamp') {
                    lampsArray.push(lamps[id]);
                } else if (type === 'Supply station') {
                    supplyStationsArray.push(supplyStations[id]);
                }
            });
            dispatch(setSelectedLampsAction(lampsArray));
            dispatch(setSelectedSupplyStationsAction(supplyStationsArray));
        }
    }
};

export const setSelectModeThunkAction = createAsyncThunk(
    'setSelectMode',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map, objectManager, mapMode },
        } = getState() as TAppState;
        if (mapMode === 'select') {
            const geoQuery = ymaps.geoQuery(objectManager.objects);
            dispatch(setGeoQueryAction(geoQuery));
        }
        mouseDownEvent = mouseDownEventFn(map, getState);
        mouseUpEvent = mouseUpEventFn(map, getState, dispatch);
        map.events.add('mousedown', mouseDownEvent);
        map.events.add('mouseup', mouseUpEvent);
    },
);
