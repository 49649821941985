import { createReducer } from '@reduxjs/toolkit';
import { addLampModelAction } from 'actions/lampModels/addLampModelAction';
import { deleteLampModelAction } from 'actions/lampModels/deleteLampModelAction';
import { modifyLampModelAction } from 'actions/lampModels/modifyLampModelAction';
import { setLampModelsAction } from 'actions/lampModels/setLampModelsAction';
import { setLampModelsPermissionsAction } from 'actions/lampModels/setLampModelsPermissionsAction';
import { setLampModelsToShowAction } from 'actions/lampModels/setLampModelsToShowAction';
import { ILampModel } from 'types/ILampModel';

interface ILampModelsReducerInitialState {
    lampModels: ILampModel[];
    lampModelsToShow: ILampModel[];
    lampModelsPermissions: { id: string; areas: string[] }[];
}

const lampModelsReducerInitialState: ILampModelsReducerInitialState = {
    lampModels: [],
    lampModelsToShow: [],
    lampModelsPermissions: [],
};

export const lampModelsReducer = createReducer(lampModelsReducerInitialState, (builder) =>
    builder
        .addCase(setLampModelsAction, (state, { payload: lampModels }) => {
            state.lampModels = lampModels;
        })
        .addCase(addLampModelAction, (state, { payload: newLampModel }) => {
            state.lampModels.push(newLampModel);
        })
        .addCase(modifyLampModelAction, (state, { payload: { id, fields } }) => {
            state.lampModels = state.lampModels.map((item) => {
                if (item.id === id) {
                    return { ...item, ...fields };
                }
                return item;
            });
        })
        .addCase(deleteLampModelAction, (state, { payload: id }) => {
            state.lampModels = state.lampModels.filter((item) => item.id !== id);
        })
        .addCase(setLampModelsToShowAction, (state, { payload: models }) => {
            if (models.length === 0) {
                state.lampModelsToShow = state.lampModels;
                return;
            }
            state.lampModelsToShow = models;
        })
        .addCase(setLampModelsPermissionsAction, (state, { payload: permissions }) => {
            state.lampModelsPermissions = permissions;
        }),
);
