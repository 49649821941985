import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDispatchProps, IStateProps } from './types';

export const LoginChecker: React.FC<IStateProps & IDispatchProps> = ({
    children,
    loginRequestStatus,
    user,
    getCurrentUser,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        if (loginRequestStatus === 'initial') {
            getCurrentUser();
        } else if (loginRequestStatus === 'error') {
            navigate('/login', { replace: true });
        } else if (loginRequestStatus === 'success') {
            if (user && user.role !== 'admin' && path !== '/menu' && path !== '/map') {
                navigate('/menu');
                return;
            }
            if (path === '/' || path === '/login') navigate('/menu');
        }
    }, [loginRequestStatus]);
    if (!user && path !== '/login') {
        return <></>;
    }
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {/* {loginRequestStatus !== 'initial' && loginRequestStatus !== 'requested' && children} */}
            {children}
        </div>
    );
};
