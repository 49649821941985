import { INetworkService } from 'types/api/INetworkService';
import { ISupplyStation } from 'types/ISupplyStation';

interface IPayload {
    id: string;
    fields: Partial<ISupplyStation>;
}

export const updateSupplyStationsApiCall = (networkService: INetworkService, data: IPayload[]) =>
    networkService.put({ url: '/api/supplyStations', data });
