import { Input } from 'components/common/Input';
import { PointDeleteConfirmationDialog } from 'components/PointDeleteConfirmationDialog/PointDeleteConfirmationDialog';
import { useEffect, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { ISupplyStation } from 'types/ISupplyStation';
import { setDraggableEndCallback } from 'utils/draggableEndCallback';
import { getPlacemarkCoordinates } from 'utils/placemark/getPlacemarkCoordinates';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';
import styles from './SupplyStationMenu.module.scss';
import { IDispatchProps, IFormRenderProps, IOwnProps, IStateProps } from './types';
import { getDataToSend, getInitialFields, getSupplyStationFields } from './utils';

export const SupplyStationMenu: React.FC<IOwnProps & IStateProps & IDispatchProps> = ({
    placemarkData,
    currentSupplyStation,
    type,
    allowWrite,
    resetCurrentPlacemark,
    deleteSupplyStation,
    handleTypeChange,
    addSupplyStation,
    updateSupplyStation,
}) => {
    const { placemark, saved } = placemarkData;
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    let submit: undefined | any;
    const handleEnterClick = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (submit) submit();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEnterClick);
        return () => {
            document.removeEventListener('keydown', handleEnterClick);
            setDraggableEndCallback(() => {});
        };
    }, []);

    const validate = (values: IFormRenderProps) => {
        const errors: { [key: string]: string } = {};
        const requiredFields = getSupplyStationFields();
        for (const field of requiredFields) {
            const fieldAsType = field as keyof IFormRenderProps;
            if (!values[fieldAsType]) {
                errors[fieldAsType] = 'Required';
            }
        }
        return errors;
    };

    const onSubmit = async (values: IFormRenderProps, form: any) => {
        if (values.action === 'another') return;
        const id = getPlacemarkProperty(placemark, 'id');
        if (values.action === 'delete') {
            setShowConfirmationDialog(true);
            return;
        }
        delete values['action'];
        const coordinates = getPlacemarkCoordinates(placemark);
        let result = undefined;
        if (saved) {
            const data = getDataToSend(currentSupplyStation as ISupplyStation, {
                ...values,
                coordinates,
            });
            result = await updateSupplyStation({
                id: id,
                fields: data,
            });
        } else {
            const { address, cabinet, name, organization } = values;
            result = await addSupplyStation({
                id,
                coordinates,
                address,
                cabinet,
                name,
                organization,
            });
        }
        if (!result.payload) {
            form.reset();
        }
    };

    const handleDeleteSupplyStation = () => {
        const id = getPlacemarkProperty(placemark, 'id');
        deleteSupplyStation(id as string, placemark);
    };

    const render = ({ handleSubmit, form, dirty }: FormRenderProps<IFormRenderProps>) => {
        submit = handleSubmit;
        setDraggableEndCallback((address) => {
            form.change('rerender', {});
            form.change('address', address);
        });
        return (
            <form onSubmit={handleSubmit} className={`${styles.form}`}>
                <Field name='rerender'>{() => null}</Field>
                {showConfirmationDialog && (
                    <PointDeleteConfirmationDialog
                        handleCancel={() => {
                            form.change('action', 'another');
                            setShowConfirmationDialog(false);
                        }}
                        handleDelete={() => {
                            form.change('action', 'another');
                            handleDeleteSupplyStation();
                        }}
                    />
                )}
                <div className='row mb-1'>
                    <label htmlFor='nameId' className='col-sm-3 col-form-label col-form-label-sm'>
                        Название
                    </label>
                    <div className='col-sm-9'>
                        <Input
                            name='name'
                            placeholder='Название'
                            className='form-control form-control-sm'
                            id='nameId'
                            disabled={!allowWrite}
                        />
                    </div>
                </div>
                {placemarkData && !placemarkData.saved && (
                    <div className='row mb-1'>
                        <label
                            htmlFor='typeId'
                            className='col-sm-3 col-form-label col-form-label-sm'>
                            Тип точки
                        </label>
                        <div className='col-sm-9'>
                            <select
                                className='form-select'
                                disabled={!allowWrite}
                                defaultValue={type}
                                onChange={() => handleTypeChange('Lamp')}>
                                <option value={'Lamp'}>Светильник</option>
                                <option value={'Supply station'}>Питающая станция</option>
                            </select>
                        </div>
                    </div>
                )}
                <div className='row mb-1'>
                    <label
                        htmlFor='cabinetId'
                        className='col-sm-3 col-form-label col-form-label-sm'>
                        Номер шкафа
                    </label>
                    <div className='col-sm-9'>
                        <Input
                            name='cabinet'
                            placeholder='Номер шкафа'
                            className='form-control form-control-sm'
                            id='cabinetId'
                            disabled={!allowWrite}
                        />
                    </div>
                </div>
                <div className='row mb-1'>
                    <label htmlFor='orgId' className='col-sm-3 col-form-label col-form-label-sm'>
                        Организация
                    </label>
                    <div className='col-sm-9'>
                        <Input
                            name='organization'
                            placeholder='Организация'
                            className='form-control form-control-sm'
                            id='orgId'
                            disabled={!allowWrite}
                        />
                    </div>
                </div>
                <div className='row mb-1'>
                    <label
                        htmlFor='addressId'
                        className='col-sm-3 col-form-label col-form-label-sm'>
                        Адрес
                    </label>
                    <div className='col-sm-9'>
                        <Input
                            name='address'
                            placeholder='Адрес'
                            className='form-control form-control-sm'
                            id='addressId'
                            disabled={true}
                        />
                    </div>
                </div>
                {allowWrite && (
                    <div className={styles.buttons}>
                        <button
                            onClick={resetCurrentPlacemark}
                            className='btn btn-secondary btn-sm'>
                            Отмена
                        </button>
                        {(!saved || dirty) && (
                            <button
                                type='submit'
                                className='btn btn-success btn-sm'
                                onClick={() => form.change('action', 'submit')}>
                                Сохранить
                            </button>
                        )}
                        {saved && (
                            <button
                                onClick={() => form.change('action', 'delete')}
                                type='submit'
                                className='btn btn-primary btn-sm'>
                                Удалить
                            </button>
                        )}
                    </div>
                )}
            </form>
        );
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={render}
            initialValues={getInitialFields(placemarkData, currentSupplyStation)}
            validate={validate}
        />
    );
};
