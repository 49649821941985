import { TRole } from 'types/TRole';
import { TRoleString } from 'types/TRoleString';

export const convertRoleToString = (role: TRole): TRoleString => {
    if (role === 'admin') {
        return 'Администратор';
    } else if (role === 'r') {
        return 'Только просмотр';
    } else if (role === 'rw') {
        return 'Редактирование';
    }
    return 'Только просмотр';
};
