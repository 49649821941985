import { createAsyncThunk } from '@reduxjs/toolkit';
import { addAreaAction } from 'actions/areas/addAreaAction';
import { setCurrentAreaAction } from 'actions/areas/setCurrentAreaAction';
import { saveAreaApiCall } from 'api/areas/saveAreaApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { showNotificationThunkAction } from 'thunks/app/showNotificationThunkAction';
import { TAppState } from 'types/TAppState';
import { getAreaCoordinates } from 'utils/areas/getAreaCoordinates';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';

interface IPayload {
    name: string;
    color: string;
}

export const addAreaThunkAction = createAsyncThunk(
    'addArea',
    async ({ name, color }: IPayload, { dispatch, getState }) => {
        const {
            areasReducer: { currentArea },
        } = getState() as TAppState;
        if (currentArea) {
            const id = getPlacemarkProperty(currentArea, 'id');
            const coordinates = getAreaCoordinates(currentArea);
            const result = await saveAreaApiCall(getNetworkService(), {
                id,
                coordinates,
                name,
                color,
            });
            if (!result.success) {
                dispatch(
                    showNotificationThunkAction({ text: 'Ошибка', timeout: 3000, type: 'error' }),
                );
                return result.statusText;
            }
            currentArea.events.add('click', () => {
                dispatch(setCurrentAreaAction(currentArea));
            });
            dispatch(addAreaAction({ coordinates, id, name, color }));
            dispatch(
                showNotificationThunkAction({ text: 'Сохранено', timeout: 3000, type: 'success' }),
            );
        }
    },
);
