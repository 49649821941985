import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentPlacemarkAction } from 'actions/map/setCurrentPlacemarkAction';
import { EPlacemarkColors } from 'constants/EPlacemarkColors';
import { getCurrentLampSelector } from 'selectors/getCurrentLampSelector';
import { getCurrentSupplyStationSelector } from 'selectors/getCurrentSupplyStationSelector';
import { setLampModelsToShowThunkAction } from 'thunks/lampModels/setLampModelsToShowThunkAction';
import { setFilterThunkAction } from 'thunks/map/setFilterThunkAction';
import { placemarkDragEndEventThunkAction } from 'thunks/placemark/placemarkDragEndEventThunkAction';
import { TAppState } from 'types/TAppState';
import { getFeature } from 'utils/getFeature';
import { getNewPlacemark } from 'utils/placemark/getNewPlacemark';
import { setPlacemarkAddress } from 'utils/placemark/setPlacemarkAddress';
import { getPlacemarkProperty } from 'utils/placemarkProperties/getPlacemarkProperty';
import { setPlacemarkProperty } from 'utils/placemarkProperties/setPlacemarkProperty';

export const featureClickEventThunkAction = createAsyncThunk(
    'featureClickEvent',
    async (e: any, { dispatch, getState }) => {
        const {
            mapReducer: { objectManager, currentPlacemarkData, map, mapMode },
            accountReducer: { user },
            lampModelsReducer: { lampModels },
        } = getState() as TAppState;
        if (mapMode === 'point') {
            const featureId = e.get('objectId');
            const feature = objectManager.objects.getById(featureId);
            const nextId = feature.id;
            if (currentPlacemarkData) {
                const previousId = getPlacemarkProperty(currentPlacemarkData.placemark, 'id');
                if (nextId !== previousId) {
                    if (currentPlacemarkData.saved) {
                        const currentLamp = getCurrentLampSelector(getState() as TAppState);
                        const currentSupplyStations = getCurrentSupplyStationSelector(
                            getState() as TAppState,
                        );
                        const previousFeature = getFeature(
                            currentLamp,
                            currentSupplyStations,
                            lampModels,
                        );
                        if (previousFeature) {
                            objectManager.add(previousFeature);
                        }
                    }
                    map.geoObjects.remove(currentPlacemarkData.placemark);
                }
            }
            await dispatch(setLampModelsToShowThunkAction(feature.geometry.coordinates));
            const newPlacemark = getNewPlacemark({
                id: feature.id,
                coordinates: feature.geometry.coordinates,
                preset: feature.options.preset,
                draggable: true,
                color: EPlacemarkColors.TEMP,
            });
            setPlacemarkProperty(newPlacemark, { iconContent: feature.properties.iconContent });
            await setPlacemarkAddress(newPlacemark);
            if (user?.role !== 'r') {
                newPlacemark.events.add('dragend', async (e: any) =>
                    dispatch(placemarkDragEndEventThunkAction(e)),
                );
            }
            dispatch(setCurrentPlacemarkAction({ placemark: newPlacemark, saved: true }));
            map.geoObjects.add(newPlacemark);
            objectManager.remove([featureId]);
            const objects: { [id: string]: number } = {};
            if (feature.properties.type === 'Supply station') {
                dispatch(
                    setFilterThunkAction({
                        type: 'Supply station',
                        field: { supply_station: feature.id },
                        replace: true,
                    }),
                );
            }
            for (const id in objectManager._filter._objects) {
                const { object } = objectManager._filter._objects[id];
                const result = object.properties.supply_station === feature.id;
                if (result) {
                    if (objects[object.properties.model]) {
                        objects[object.properties.model] += 1;
                    } else {
                        objects[object.properties.model] = 1;
                    }
                }
            }
            let balloon = '';
            for (const id in objects) {
                const name = lampModels.find((i) => i.id === id);
                balloon += `<li>${name?.name} - ${objects[id]}</li>`;
            }
            if (Object.keys(objects).length !== 0) {
                balloon = `<ul>${balloon}</ul>`;
                newPlacemark.properties.set('balloonContent', balloon);
            }
        }
    },
);
