import { Input } from 'components/common/Input';
import { Form, FormRenderProps } from 'react-final-form';
import styles from './LoginPage.module.scss';
import { IDispatchProps, IFormRenderProps } from './types';

export const LoginPage: React.FC<IDispatchProps> = ({ login }) => {
    const onSubmit = (values: IFormRenderProps) => {
        login(values);
    };

    const render = ({ handleSubmit }: FormRenderProps<IFormRenderProps>) => (
        <form onSubmit={handleSubmit} className='form'>
            <div className='mb-3'>
                <label htmlFor='username' className='form-label'>
                    Логин
                </label>
                <Input name='username' className='form-control' id='username' />
            </div>
            <div className='mb-3'>
                <label htmlFor='password' className='form-label'>
                    Пароль
                </label>
                <Input name='password' className='form-control' id='password' type='password' />
            </div>
            <div className={styles.buttons}>
                <button type='submit' className='btn btn-primary'>
                    Войти
                </button>
            </div>
        </form>
    );
    const style = `container-xl ${styles.wrapper}`;

    return (
        <div className={style}>
            <Form onSubmit={onSubmit} render={render} />
        </div>
    );
};
