import { createAction } from '@reduxjs/toolkit';
import { ELampModelsActions } from 'constants/actions/ELampModelsActions';
import { ILampModel } from 'types/ILampModel';

interface IPayload {
    id: string;
    fields: Partial<ILampModel>;
}

export const modifyLampModelAction = createAction<IPayload>(ELampModelsActions.MODIFY_LAMP_MODEL);
