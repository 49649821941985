import { connect } from 'react-redux';
import { getCurrentPlacemarkTypeSelector } from 'selectors/getCurrentPlacemarkTypeSelector';
import { resetCurrentPlacemarkThunkAction } from 'thunks/map/resetCurrentPlacemarkThunkAction';
import { TAppDispatch } from 'types/TAppDispatch';
import { TAppState } from 'types/TAppState';
import { PlacemarkMenu } from './PlacemarkMenu';
import { IDispatchProps, IStateProps } from './types';

const mapStateToProps = (state: TAppState): IStateProps => ({
    currentPlacemarkType: getCurrentPlacemarkTypeSelector(state),
    currentPlacemarkData: state.mapReducer.currentPlacemarkData,
    user: state.accountReducer.user,
});

const mapDispatchToProps = (dispatch: TAppDispatch): IDispatchProps => ({
    resetCurrentPlacemark: () => dispatch(resetCurrentPlacemarkThunkAction()),
});

export const PlacemarkMenuConnected = connect(mapStateToProps, mapDispatchToProps)(PlacemarkMenu);
