import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLampModelsToShowAction } from 'actions/lampModels/setLampModelsToShowAction';
import { getIncludingAreasByPointApiCall } from 'api/areas/getIncludingAreasByPointApiCall';
import { getNetworkService } from 'api/getNetworkService';
import { ILampModel } from 'types/ILampModel';
import { TAppState } from 'types/TAppState';

export const setLampModelsToShowThunkAction = createAsyncThunk(
    'setLampModelsToShow',
    async (point: [number, number], { getState, dispatch }) => {
        const response = await getIncludingAreasByPointApiCall(getNetworkService(), {
            lat: point[0].toString(),
            lon: point[1].toString(),
        });
        if (!response.success) {
            return 'error';
        }
        const areas = response.data as { id: string }[];
        if (areas.length === 0) {
            dispatch(setLampModelsToShowAction([]));
            return;
        }
        const {
            lampModelsReducer: { lampModelsPermissions, lampModels },
        } = getState() as TAppState;
        const lampsModelsToShow = new Set<ILampModel>();
        for (const area of areas) {
            lampModelsPermissions.forEach((p) => {
                if (p.areas.includes(area.id)) {
                    const lampModel = lampModels.find((lm) => lm.id === p.id);
                    if (lampModel) {
                        lampsModelsToShow.add(lampModel);
                    }
                }
            });
        }
        dispatch(setLampModelsToShowAction(Array.from(lampsModelsToShow)));
    },
);
