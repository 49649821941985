import { createReducer } from '@reduxjs/toolkit';
import { setNotificationToShowAction } from 'actions/app/setNotificationToShowAction';
import { INotification } from 'types/INotification';

interface IAppReducerInitialState {
    notificationToShow: INotification | null;
}

const appInitialState: IAppReducerInitialState = {
    notificationToShow: null,
};

export const appReducer = createReducer(appInitialState, (builder) =>
    builder.addCase(setNotificationToShowAction, (state, { payload: notification }) => {
        state.notificationToShow = notification;
    }),
);
