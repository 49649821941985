import { INetworkService } from 'types/api/INetworkService';

interface IPayload {
    lat: string;
    lon: string;
}

export const getIncludingAreasByPointApiCall = (
    networkService: INetworkService,
    { lat, lon }: IPayload,
) => {
    const requestUrl = new window.URL('/api/areasByPoint', window.location.origin);
    requestUrl.searchParams.append('lat', lat);
    requestUrl.searchParams.append('lon', lon);
    return networkService.get({ url: requestUrl.toString() });
};
