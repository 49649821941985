import { IUser } from 'types/IUser';
import { TAppState } from 'types/TAppState';

export const getUserSelector = (state: TAppState): IUser | null => {
    const path = location.pathname;
    const pathSplitted = path.split('/');
    const item = state.usersReducer.users.find(
        (item) => item.id === pathSplitted[pathSplitted.length - 1],
    );
    if (!item) return null;
    return item;
};
