import { createAsyncThunk } from '@reduxjs/toolkit';
import { setMapAction } from 'actions/map/setMapActions';
import { setObjectManagerAction } from 'actions/map/setObjectManagerAction';
import { EPlacemarkTypes } from 'constants/EPlacemarkIcons';
import { loadFeatureThunkAction } from 'thunks/feature/loadFeatureThunkAction';
import { TAppState } from 'types/TAppState';
import { addMapClickEventThunkAction } from './addMapClickEventThunkAction';

export const initMapThunkAction = createAsyncThunk(
    'initMap',
    async (_: void, { getState, dispatch }) => {
        const {
            mapReducer: { map: mapInstance },
            settingsReducer: { mapCenter, mapZoom },
        } = getState() as TAppState;
        if (!mapInstance) {
            const map = new ymaps.Map(
                'map',
                {
                    center: mapCenter,
                    zoom: mapZoom,
                },
                {
                    searchControlProvider: 'yandex#search',
                },
            );
            dispatch(
                setObjectManagerAction(new ymaps.ObjectManager({ clusterize: true, maxZoom: 15 })),
            );
            await addUserLocationToMap(map);
            dispatch(addMapClickEventThunkAction(map));
            dispatch(setMapAction(map));
            dispatch(loadFeatureThunkAction());
        }
    },
);

const addUserLocationToMap = async (map: any) => {
    try {
        const geolocation = ymaps.geolocation;
        const browserGeolocation = await geolocation.get({
            provider: 'browser',
            mapStateAutoApply: false,
        });
        browserGeolocation.geoObjects.options.set('preset', EPlacemarkTypes.USER_LOCATION);
        map.geoObjects.add(browserGeolocation.geoObjects);
    } catch (e) {
        console.log(e);
    }
};
